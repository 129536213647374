import Slider from "react-slick";

import {
    accLogo2,
    accLogo6,
    accLogo7,
    accLogo8,
    accLogo9,
    BackgroundImg,
    badgeIcon1,
    badgeIcon2,
    director_image,
    icontag,
    imageAB1,
    imageAB2,
    imageAB3,
    imageAB4,
    imageAB42,
    Director_Image,
    ImageGallery1,
    ImageGallery2,
    ImageGallery3,
    VectorEye,
    VectorEye2,
    VectorTarget,
    VectorTarget2,
} from "src/assets";
import {
    useInView,
    UseInViewOptions,
    useMotionValue,
    useSpring,
} from "framer-motion"
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { motion, Variants } from "framer-motion";
import Marquee from "react-fast-marquee";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { caseStudies } from "../CaseStudy/SingleCaseStudy/data";
import './style.css'
import SvgGraph from "./SvgGraph";

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 1595,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 1900,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 2,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 1281,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 1318,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 1486,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                dots: true,
                infinite: true,
            },
        },

        {
            breakpoint: 1640,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 2,
                dots: true,
                infinite: true,
            },
        },

        {
            breakpoint: 1330,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 866,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
                infinite: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                infinite: true,
            },
        },
    ],
};

const Index = () => {
    const ref = useRef(null)

    const [isVisible, setIsVisible] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 991);
    const [isDesktop2, setIsDesktop2] = useState(window.innerWidth >= 768);

    const handleResize = () => {
        setIsDesktop(window.innerWidth >= 991);
        setIsDesktop2(window.innerWidth >= 768);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let delay = 0.2
    const variants = {
        hidden: { opacity: 0 },
        visible: (i: any) => ({
            y: 0,
            opacity: 1,
            transition: { delay: i * delay },
        }),
    }
    const word1 = '2 0 1 8';
    const word2 = '4 . 5 / 5'
    const _words = word1.split(" ");
    const _words2 = word2.split(" ");
    const navigate = useNavigate()

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect(); // Stop observing once visible
            }
        });
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    const direction = "up";
    const value = 0;
    const decimalPlaces = 0

    const ref2 = useRef<HTMLSpanElement>(null);
    const ref3 = useRef<HTMLSpanElement>(null);
    const ref5 = useRef<HTMLSpanElement>(null);

    const motionValue1 = useMotionValue(direction === "up" ? value : 1350);
    const motionValue2 = useMotionValue(direction === "up" ? value : 96);
    const motionValue3 = useMotionValue(direction === "up" ? value : 20);


    const springValue1 = useSpring(motionValue1, {
        damping: 60,
        stiffness: 100,
    });
    const springValue2 = useSpring(motionValue2, {
        damping: 60,
        stiffness: 100,
    });
    const springValue3 = useSpring(motionValue3, {
        damping: 60,
        stiffness: 100,
    });

    const isInView1 = useInView(ref2, { once: true, margin: "0px" });
    const isInView2 = useInView(ref3, { once: true, margin: "0px" });
    const isInView3 = useInView(ref3, { once: true, margin: "0px" });

    useEffect(() => {
        isInView1 &&
            setTimeout(() => {
                motionValue1.set(direction === "up" ? 1350 : value);
            }, delay * 1000);
        isInView2 &&
            setTimeout(() => {
                motionValue2.set(direction === "up" ? 96 : value);
            }, delay * 1000);
        isInView3 &&
            setTimeout(() => {
                motionValue3.set(direction === "up" ? 20 : value);
            }, delay * 1000);
    }, [motionValue1, motionValue2, motionValue3, isInView1, isInView2, isInView3, delay, value, direction]);

    useEffect(
        () => {
            springValue1.on("change", (latest) => {
                if (ref2.current) {
                    ref2.current.textContent = Intl.NumberFormat("en-US", {
                        minimumFractionDigits: decimalPlaces,
                        maximumFractionDigits: decimalPlaces,
                    }).format(Number(latest.toFixed(decimalPlaces))) + "+";
                }
            })
            springValue2.on("change", (latest) => {
                if (ref3.current) {
                    ref3.current.textContent = Intl.NumberFormat("en-US", {
                        minimumFractionDigits: decimalPlaces,
                        maximumFractionDigits: decimalPlaces,
                    }).format(Number(latest.toFixed(decimalPlaces))) + "%";
                }
            })
            springValue3.on("change", (latest) => {
                if (ref5.current) {
                    ref5.current.textContent = Intl.NumberFormat("en-US", {
                        minimumFractionDigits: decimalPlaces,
                        maximumFractionDigits: decimalPlaces,
                    }).format(Number(latest.toFixed(decimalPlaces))) + "+";
                }
            })

        },
        [springValue1, springValue2, springValue3, decimalPlaces],
    );

    return (
        <div >

            <Helmet>
                <title>
                    About-Us | Ditinus
                    Technology
                </title>
            </Helmet>
            <main className="">
                <section className="about-main-section position-relative">
                    <img src={BackgroundImg} className="img-fluid aboutPageBg w-100" style={{ opacity: 0.4, zIndex: -1 }} alt="img" />
                    <section className="subBannerAbout">
                        <div className="container d-flex justify-content-center text-center ">
                            <h2 className="heading-aboutus text-white">
                                Fueling Dreams Through <br /> Technology
                            </h2>
                        </div>
                    </section>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="row justify-content-space-between p-0"
                            //  data-aos="fade-up"
                            >
                                <div className="col-lg-8 col-md-12 col-sm-12 p-0 m-0 p-2">
                                    <div className="">
                                        <h3 className="text-WH font-weight-normal">
                                            About Ditinus Technology
                                        </h3>

                                        <p className="text-WH mt-2">
                                            Proudly Standing Among{" "}
                                            <span style={{ color: "#0D72B8" }}>
                                                {" "}
                                                The Best IT Companies in India!
                                            </span>
                                        </p>
                                    </div>

                                    <div className="">
                                        <div className="col-12 col-sm-12 col-lg-10 col-md-12 col-xl-10 text-white" style={{ fontWeight: '300' }}>
                                            <p>
                                                Founded with a zeal to pump innovation into the IT sector,
                                                Ditinus Technology initiated its operations in 2018. What
                                                first began as an innovative approach to the tech industry,
                                                now stands proudly among the best IT companies in India. We
                                                aim to and have successfully, continued to deliver
                                                high-quality, cost-effective, dependable, on-time, and
                                                result-oriented web solutions to clients worldwide.

                                                <br />
                                                <br />


                                                Our expertise in web development services, web designing services, Digital marketing services, and Business Process services has rendered multiple successful projects both locally and internationally.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 p-2 m-0  p-sm-2 p-md-2 p-xl-2 p-lg-2 position-relative">
                                    <img src={imageAB1} alt="icon" className="rounded-3" style={{ width: "100%" }} />
                                    <div className="position-absolute badgeIcon1 cursor-pointer" style={{ cursor: 'pointer' }}>
                                        <img src={badgeIcon1} alt="" onClick={() => navigate('/')} />
                                    </div>
                                </div>
                            </div>

                            <div className="row p-1">

                                <div className="col-6 col-xl-2 col-lg-3 col-md-6 p-0 m-0 p-2" >

                                    <div className="servicesAboteBox2 content">
                                        <p className="text-white p-0 m-0 about-card-111">SINCE</p>
                                        <div ref={ref}>
                                            <motion.h1
                                                variants={variants}
                                                initial="hidden"
                                                animate={isVisible ? "visible" : "hidden"}
                                                className='fw-bold about-card1 p-0 m-0'
                                            >
                                                {_words.map((word, i) => (
                                                    <motion.span key={word} variants={variants} custom={i}>
                                                        {word}
                                                    </motion.span>
                                                ))}
                                            </motion.h1>
                                        </div>

                                        <p className="text-white p-0 m-0 about-card-111">in Business</p>
                                    </div>

                                </div>
                                <div className="col-6 col-xl-2 col-lg-3 col-md-6 p-0 m-0 p-2"   >
                                    <div className="servicesAboteBox2">
                                        <h5 className='fw-bold about-card1 p-0 m-0 mt-4'>
                                            <span ref={ref2} /></h5>
                                        <p className="text-white p-0 m-0 about-card-111">Projects completed</p>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-2 col-lg-3 col-md-6 p-0 m-0 p-2">
                                    <div className="servicesAboteBox2">
                                        <h5 className='fw-bold about-card1 p-0 m-0 mt-4'>  <span ref={ref3} /></h5>
                                        <p className="text-white p-0 m-0 about-card-111">Client success rate</p>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-2 col-lg-3 col-md-6 p-0 m-0 p-2">
                                    <div className="servicesAboteBox2">
                                        {/* <motion.h1
                                            variants={variants}
                                            initial="hidden"
                                            animate={isVisible ? "visible" : "hidden"}
                                            className='fw-bold about-card1 p-0 m-0'
                                        >
                                            {_words2.map((word, i) => (
                                                <motion.span key={word} variants={variants} custom={i}>
                                                    {word}
                                                </motion.span>
                                            ))}
                                        </motion.h1> */}
                                        <h5 className='fw-bold about-card1 p-0 m-0 mt-4'>  <span ref={ref5} /></h5>
                                        <p className="text-white p-0 m-0 about-card-111">Industries Served</p>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-xl-4 col-lg-12 col-md-12 p-0 m-0 p-2 order-first order-lg-last order-md-first order-sm-first order-xl-fifth"    >
                                    <div
                                        className="servicesAboteBox3 rounded-3"
                                        style={{
                                            backgroundColor: "#0D72B8"
                                        }}
                                    >
                                        <h5
                                            className="p-text18 text-WH "
                                            style={{
                                                fontSize: "26px",
                                                lineHeight: "35px",
                                                fontWeight: 600,
                                            }}
                                        >
                                            Lets Create Something Remarkable Together!
                                        </h5>
                                        <a
                                            href="#contact-form"
                                            type="button"
                                            className="transition text-nowrap rounded-pill fill-btn3 col-4 d-flex justify-content-center"
                                        >
                                            Contact
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-0 mt-2 about-back-drop" >
                                {/* First Column (will be first on desktop) */}
                                <div className="p-0 p-2 col-7 col-md-7 col-sm-7 col-lg-4 col-xl-4 position-relative ">
                                    <img src={imageAB4} alt="icon" className="img-fluid h-100 w-100 rounded-3" />
                                    <div className="position-absolute badgeIcon2" style={{ cursor: 'pointer' }}>
                                        <img src={badgeIcon2} onClick={() => navigate('/')} alt="" />
                                    </div>
                                </div>

                                {/* Middle Column (will be first on mobile) */}
                                <div className="p-0 p-2 col-12 col-md-12 col-sm-12 col-lg-5 col-xl-5 order-md-first order-first order-sm-first order-xl-0 order-lg-0 order-xl-0 ">
                                    <img src={imageAB3} alt="icon" className="img-fluid h-100 w-100 rounded-3 " />
                                </div>

                                {/* Third Column (will be last on mobile) */}
                                <div className="p-0 p-2 col-5 col-md-5 col-sm-5 col-lg-3 col-xl-3 position-relative">
                                    <img src={isDesktop ? imageAB2 : imageAB42} alt="icon" className="img-fluid h-100 w-100 rounded-3" />
                                    <div className="position-absolute badgeIcon2" style={{ cursor: 'pointer' }}>
                                        <img src={badgeIcon2} onClick={() => navigate('/')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row p-0 p-2">

                                <div className="row col-12 p-0 m-0 mt-3 rounded-5 gap-4 gap-lg-0 gap-xl-0 px-3 d-flex flex-row align-items-start justify-content-evenly py-5 " style={{
                                    background: '#FFFFFF1C'
                                }}>

                                    <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 row p-0 m-0 target-vision d-flex flex-column flex-lg-row flex-md-column flex-xl-row flex-sm-column gap-3 gap-md-3 gap-sm-3 gap-lg-0 gap-xl-0 ">
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-2 col-lg-2 ">
                                            <img src={VectorTarget} className="" alt="" />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-10 col-lg-10 position-relative d-flex flex-column p-0 m-0 ">
                                            <div className="position-absolute top-0 end-0 col-6 col-sm-6 col-md-3 col-lg-6 col-xl-6">
                                                <img src={VectorTarget2} className="img-fluid w-100 h-100" alt="" />
                                            </div>
                                            <h3 className="text-white p-0 m-0">Mission</h3>
                                            <div className="h-25 mt-3 mb-3 mt-sm-3 mt-md-3 md-lg-0 md-sm-0 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0">
                                                <h5 className=" text-white p-0 m-0 col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                                                    We don’t just make promises
                                                    We bring them to life!
                                                </h5>
                                            </div>
                                            <div className=" h-50">

                                                <p className="text-white p-0 m-0 fw-light mb-4 mb-lg-0 mb-xl-0 col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11">At Ditinus Technology, our goal goes way beyond just doing business—we want you to feel right at home with us. We’re here to make your experience smooth, supportive, and genuinely rewarding. When you work with us, you’re not just a client; you’re part of a journey toward building technology that truly makes a difference.</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 row p-0 m-0  d-flex flex-column flex-lg-row flex-md-column flex-xl-row flex-sm-column gap-3 gap-md-3 gap-sm-3 gap-lg-0 gap-xl-0">
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-2 col-lg-2">
                                            <img src={VectorEye} className="" alt="" />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-10 col-lg-10 position-relative d-flex flex-column  p-0 m-0 ">
                                            <div className="position-absolute top-0 end-0  col-6 col-sm-6 col-md-3 col-lg-6 col-xl-6">
                                                <img src={VectorEye2} className="img-fluid w-100 h-100" alt="" />
                                            </div>
                                            <h3 className="text-white p-0 m-0">Vision</h3>
                                            <div className="h-25 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-3">
                                                <h5 className="text-white p-0 m-0">
                                                    Our Vision for Beyond Tomorrow
                                                </h5>
                                            </div>
                                            <div className="h-50">
                                                <p className="text-white p-0 m-0 fw-light col-12 col-sm-12 col-lg-11 col-xl-11 col-md-12">We’re on a mission to craft a future where technology is not just accessible but a breeze to use and totally transformative! Our goal? We aim to empower our clients to explore their full potential and smash their goals like never before.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


                <section className="graph-section">
                    <SvgGraph />
                </section>


                <section className="director-section">
                    <div className="container h-auto">


                        <div className="row px-4 px-sm-4 px-lg-0 px-xl-0 px-md-4 gap-5 gap-md-5 gap-lg-0 gap-xl-0 dir_director">

                            <div className=" col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 d-flex justify-content-center">
                                <img
                                    src={Director_Image}
                                    alt="Background"
                                    className="col-12 img-fluid h-100 w-100"
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 position-relative">
                                <div className="director-comment-pos col-12 card-float2 d-flex flex-row  justify-content-center align-items-center">
                                    <img
                                        src={director_image}
                                        alt="Background"
                                        className="col-12 img-fluid position-relative"
                                    />
                                    <div className="background-right">
                                        <motion.div className="blob-right"></motion.div>
                                        <motion.div className="blob-right"></motion.div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>

                <section className="why-choose-about">
                    <div className="container">
                        <div className="row p-0 m-0">
                            <div className="col-11 col-sm-11 col-md-12 col-xl-4 col-lg-5">
                                <div className="col-12 col-md-12 col-sm-12 col-lg-10 col-xl-10  d-flex flex-column gap-1 px-2 py-1">
                                    <h3 className="p-0 m-0  text-white borderMainCardtext text-nowrap">
                                        Why You should
                                    </h3>
                                    <h3 className="borderMainCardtext text-nowrap">
                                        <span className="" style={{
                                            color: '#0D72B8'
                                        }}>Choose us</span><span className="text-white">?</span>
                                    </h3>
                                    <p className="border-bottom text-white borderMainCardDesc text-nowrap p-0 m-0 py-2">Exceptionally tailored to you</p>
                                </div>
                            </div>
                            <div className=" col-lg-1 col-xl-2 p-0 m-0">

                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 row  p-0 m-0  d-flex justify-content-end ">
                                <div className="col-12 col-md-12 col-sm-12 col-lg-11 col-xl-11 p-0 m-0 borderCardchoose ">

                                    <div className="col-12 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row justify-content-between  px-4 px-md-4 px-sm-4 px-lg-0 px-xl-0 pb-0 pb-lg-4 pb-xl-4 pb-sm-0 pb-md-0">
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4 p-0 m-0  py-3 py-lg-0 py-xl-0 py-sm-3 py-md-3">
                                            <h3 className="p-0 m-0 text-white borderCardheader">Customized IT Solutions</h3>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6 p-0 m-0 ">
                                            <p className="p-0 m-0 text-white borderCardDesc">Experience IT services in India that are exceptionally tailored to meet your unique business needs, ensuring you get exactly what you require for success.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row justify-content-between  px-4 px-md-4 px-sm-4 px-lg-0 px-xl-0 py-0 py-lg-4 py-xl-4 py-sm-0 py-md-0">
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4  p-0 m-0 py-3 py-lg-0 py-xl-0 py-sm-3 py-md-3">
                                            <h3 className="p-0 m-0 text-white borderCardheader">Comprehensive  Service Suite</h3>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6  p-0 m-0">
                                            <p className="p-0 m-0 text-white borderCardDesc">Enhance your online presence with our extensive range of advanced services, including Web Development, Web Design, Digital Marketing, Business process services.</p>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row justify-content-between  px-4 px-md-4 px-sm-4 px-lg-0 px-xl-0 py-0 py-lg-4 py-xl-4 py-sm-0 py-md-0">
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4 p-0 m-0  py-3 py-lg-0 py-xl-0 py-sm-3 py-md-3">
                                            <h3 className="p-0 m-0 text-white borderCardheader">Boost Your Business</h3>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6  p-0 m-0">
                                            <p className="p-0 m-0 text-white borderCardDesc">Leverage our expertise to elevate your brand and achieve your business goals through innovative technology and strategic solutions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="image-gallery-section1">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 row d-flex justify-content-center align-items-center">
                                <div className="col-12 d-flex flex-column justify-content-center align-items-center ">
                                    <h5 className="text-white gallery-header1">Cheers To the</h5>
                                    <h2 className="gallery-header2" style={{ color: '#0D72B8' }}>Champions</h2>
                                    <h6 className="text-white gallery-header3">Check out our amazing team as they soar to new heights!</h6>
                                </div>
                                <div className="col-12 d-flex justify-content-center align-items-center p-0 m-0 mt-5">
                                    {isDesktop2 ?
                                        <img src={ImageGallery1} className="img-fluid" alt="" />
                                        :
                                        <img src={ImageGallery3} className="img-fluid" alt="" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="image-gallery-section2">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 row d-flex  align-items-lg-start p-0 m-0">
                                <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-12 d-flex flex-column justify-content-start p-xl-0 p-lg-0">
                                    <h2 className="text-white gallery2-header1 text-start">Shining {!isDesktop2 || !isDesktop ? null : <br />}Together</h2>
                                    <h2 className="gallery2-header1  w-100 text-start" style={{ color: '#0D72B8' }}>The Ditinus Team</h2>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-9 col-xl-9 col-md-12 d-flex justify-content-center align-items-center">
                                    <img src={ImageGallery2} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="accredation-section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <h4 className="col-12 gallery2-header1 text-center text-white  pb-5">Our Prestigious <span style={{ color: '#0D72B8' }}>Accreditations</span></h4>
                        </div>
                    </div>
                    {/* <div className={`px-5 px-sm-5 px-md-5 px-lg-5 px-xl-5 px-xxl-5 ${isDesktop ? '' : 'container'}`}>
                        <div className="row">
                            <div className="py-4 py-sm-4 py-md-3 py-xl-5 py-lg-5 rounded-4 seal-icon-container" style={{ background: '#0D72B8' }}>
                                <div className="row p-0 m-0 justify-content-lg-center justify-content-sm-evenly justify-content-evenly justify-content-md-evenly justify-content-xl-evenly justify-content-lg-evenly align-content-center align-items-center gap-3 gap-sm-2 gap-md-3 gap-lg-5 gap-xl-5 gap-xxl-3">
                                    <div className="col-5 col-sm-4 col-md-3 h-100  col-lg-2 p-0 m-0 rounded-3">
                                        <div className="d-flex bg-white rounded-3  align-items-center justify-content-center align-self-center">
                                            <img src={accLogo1} alt="Google" className="img-fluid rounded-3 h-100 w-100 seals-icon" />
                                        </div>
                                    </div>
                                    <div className="col-5 col-sm-4 col-md-3 h-100  col-lg-2 p-0 m-0 rounded-3">
                                        <div className="d-flex bg-white rounded-3  align-items-center justify-content-center align-self-center">
                                            <img src={accLogo2} alt="ASM" className="img-fluid rounded-3  h-100 w-100 seals-icon" />
                                        </div>
                                    </div>
                                    <div className="col-5 col-sm-4 col-md-3 h-100  col-lg-3  p-0 m-0 rounded-3">
                                        <div className="d-flex rounded-3  bg-white align-items-center justify-content-center align-self-center">
                                            <img src={accLogo3} alt="Stripe" className=" rounded-3 h-100 w-100 py-2 py-sm-2 py-md-0 py-lg-0 py-xl-0 seals-icon" />
                                        </div>
                                    </div>
                                    <div className="col-5 col-sm-4 col-md-3 h-100  col-lg-2  p-0 m-0 rounded-3">
                                        <div className="d-flex bg-white rounded-3  align-items-center justify-content-center align-self-center">
                                            <img src={accLogo4} alt="Godaddy" className="img-fluid rounded-3 h-100 w-100 seals-icon" />
                                        </div>
                                    </div>
                                    <div className="col-5 col-sm-4 col-md-3 h-100  col-lg-2 text-center p-0 m-0 rounded-3">
                                        <div className="d-flex bg-white rounded-3  align-items-center justify-content-center align-self-center">
                                            <img src={accLogo5} alt="Facebook" className="img-fluid rounded-3 h-100 w-100 seals-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <Marquee pauseOnHover className="marque-icon-border2">
                        <div className="marque-icon-border2 d-flex flex-row flex-nowrap py-4 justify-content-between align-items-between gap-5">

                            <img src={accLogo6} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo7} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo8} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo9} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo2} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo6} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo7} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo8} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo9} className="img-fluid marque-client-icon p-4" alt="" />
                            <img src={accLogo2} className="img-fluid marque-client-icon p-4" alt="" />

                        </div>
                    </Marquee>
                </section>

                <section className="clientLogoSection">
                    <div className="container">
                        <div className="row justify-content-center pb-5">
                            <h4 className="col-12 gallery2-header1 text-center text-white">
                                Recent <span style={{ color: '#0D72B8' }}>Milestones</span> in our Journey
                            </h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 case-study-slider px-5 ">
                            <Slider {...settings}>
                                {caseStudies && caseStudies?.map((el, index) => (
                                    <div className="caseStudies-about-container p-3" data-aos="zoom-in-left"
                                        data-aos-duration={500}>
                                        <Link
                                            to={`/case-study/${el.slug}`}
                                        >
                                            <div className="caseStudies-about rounded-4 py-3 px-4 d-flex flex-column gap-3">
                                                <div className="d-flex flex-column">
                                                    <figure className="p-0 m-0">
                                                        <img
                                                            className="w-100 rounded-4 p-0 m-0"
                                                            src={el.image2}
                                                            alt="Portfolio"
                                                        />
                                                    </figure>
                                                    <div className="d-flex flex-row  gap-3 flex-wrap p-0 m-0 mt-2 jusitfy-content-start " key={index}>
                                                        {el?.keywords.map((item, index) => (
                                                            <div className="d-flex flex-row gap-1">
                                                                <img src={icontag} loading="lazy" className="p-0" alt="" />
                                                                <p className="text-white p-0 m-0 text-wrap fw-normal caseStudies-tag">
                                                                    {item}
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <h5 className="text-white m-0 text-start">
                                                    {el?.title}
                                                </h5>
                                            </div>
                                        </Link>

                                    </div>
                                ))}

                            </Slider>
                        </div>
                    </div>
                </section >

            </main >
        </div >
    );
};

export default Index;
// {isDesktop ? (
//     <Marquee pauseOnHover className="marque-icon-border">
//         <div className="d-flex flex-nowrap gap-5 py-4">
//             {/* Add your client logos here */}
//             <img src={clientLogo1} className="img-fluid marque-client-icon  p-3" alt="" />
//             <img src={clientLogo2} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo3} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo4} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo5} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo6} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo7} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo8} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo9} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo10} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo11} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo12} className="img-fluid marque-client-icon p-3" alt="" />
//             <img src={clientLogo13} className="img-fluid marque-client-icon p-3" alt="" />
//         </div>
//     </Marquee>
// ) : (
//     <div className="d-flex flex-row flex-wrap align-items-center  justify-content-evenly gap-4 container" style={{

//     }}>
//         {/* Display the logos in a grid for mobile/tablet */}
//         <img src={clientLogo1} className="img-fluid" alt="" />
//         <img src={clientLogo2} className="img-fluid" alt="" />
//         <img src={clientLogo3} className="img-fluid" alt="" />
//         <img src={clientLogo4} className="img-fluid" alt="" />
//         <img src={clientLogo5} className="img-fluid" alt="" />
//         <img src={clientLogo6} className="img-fluid" alt="" />
//         <img src={clientLogo7} className="img-fluid" alt="" />
//         <img src={clientLogo8} className="img-fluid" alt="" />
//         <img src={clientLogo9} className="img-fluid" alt="" />
//         <img src={clientLogo10} className="img-fluid" alt="" />
//         <img src={clientLogo11} className="img-fluid" alt="" />
//         <img src={clientLogo12} className="img-fluid" alt="" />
//         <img src={clientLogo13} className="img-fluid" alt="" />
//     </div>
// )}
// {/* <section className="container p-5 mt-5">
//     <div className="column">
//         <h2 className="text-white fw-medium" style={{ fontWeight: 700, fontSize: '50px' }}>
//             Our Story
//         </h2>
//         <h3 style={{ fontWeight: 400, fontSize: '26px' }} className="text-white fw-normal">
//             Delivering Seamless Digital Experiences since 2018
//         </h3>
//     </div>
//     <div className="about-card">
//         {data?.map((el, index) => (
//             <motion.div
//                 key={index}
//                 className="about-card-item"
//                 initial={{ opacity: 0, y: 50 }}
//                 whileInView={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.8, delay: index * 0.1 }} // Reduced delay for parallax effect
//                 style={{ overflow: 'hidden' }} // Prevent overflow issues
//             >
//                 <div className="border-line mb-3 mt-3" />
//                 <div className="p-3" style={{ height: '60vh' }}>
//                     <div className="d-flex flex-row justify-content-between w-100 mb-3">
//                         <h3 style={{ color: 'rgba(13, 114, 184, 1)' }}>
//                             {el?.title}
//                         </h3>
//                         <h3 className="text-white">{el?.date}</h3>
//                     </div>
//                     <p className="col-7 text-white">{el?.description}</p>
//                     <div className="col-12 row p-3">
//                         <div className="col-6 ">
//                             <img src={el?.image} alt="" className="img-fluid w-100 h-100" />
//                         </div>
//                         <div className="column col-6 d-flex flex-column justify-content-between">
//                             <div>
//                                 <div className="about-glass-card text-white" style={{ height: '80%', fontWeight: 600, fontSize: '20px' }}>
//                                     <p className="text-white">{el?.subDescription}</p>
//                                 </div>
//                                 <div className="h-25 p-3 d-flex flex-row">
//                                     <img src={arrow123} alt="bullet" className="img-fluid" />
//                                     <p className="text-white p-0 m-0">{el?.bulletPoints}</p>
//                                 </div>
//                             </div>
//                             <div>
//                                 <h3 className="text-white d-flex justify-content-end">
//                                     {el?.date + 1}
//                                 </h3>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </motion.div>
//         ))}
//     </div>
// </section> */}
// {/* <section className="gallery-section">
//     <div className="container">
//         <div className="row">
//             <div className="col-12 text-center">
//                 <h2 className="heading-02">Take a Look</h2>
//                 <p className="p-text">Snapshots of Our Story</p>
//             </div>
//         </div>


//         <div className="row">
//             <div className="col-lg-4 mb-0 mb-md-0 mb-sm-0 mb-xl-4 mb-lg-0 h-25">
//                 <img
//                     src={gallary1}
//                     style={{ height: "257px" }}
//                     className="w-100  shadow-1-strong  mb-4"
//                     alt="Boat on Calm Water"
//                 />
//                 <img
//                     src={gallary2}
//                     style={{ height: "384px" }}
//                     className="w-100    shadow-1-strong mb-4"
//                     alt="Wintry Mountain Landscape"
//                 />
//             </div>
//             <div className="col-lg-4 mb-0 mb-md-0 mb-sm-0 mb-xl-4 mb-lg-0 h-25">
//                 <img
//                     src={gallary3}
//                     style={{ height: "666px" }}
//                     className="w-100 shadow-1-strong  mb-4"
//                     alt="Mountains in the Clouds"
//                 />
//             </div>
//             <div className="col-lg-4 mb-4 mb-lg-0 h-25">
//                 <img
//                     src={gallary4}
//                     style={{ height: "257px" }}
//                     className="w-100 shadow-1-strong  mb-4"
//                     alt="Waves at Sea"
//                 />
//                 <img
//                     src={gallary5}
//                     style={{ height: "384px" }}
//                     className="w-100 shadow-1-strong  mb-4"
//                     alt="Yosemite National Park"
//                 />
//             </div>
//         </div>
//     </div>
// </section>
// <section className="logoSlider-section logoSlider-aboutPage">
//     <div className="container">
//         <div className="row justify-content-center">
//             <div className="col-12">
//                 <h2 className="heading-02 lh-sm text-center text-WH">
//                     The Things
//                     <span
//                         className="position-relative d-inline-block"
//                         data-aos="fade-left"
//                         data-aos-duration={900}
//                     >
//                         We Do Best
//                         <img
//                             src={bottomline}
//                             alt="line"
//                             className="position-absolute start-0 top-100 text-bottom-line w-100"
//                         />
//                     </span>
//                 </h2>
//             </div>
//             <div className="col-xl-8 col-lg-12 mt-5 text-center">
//                 <p
//                     className="p-text fw-medium text-WH "
//                     data-aos="fade-up"
//                     data-aos-duration={900}
//                 >
//                     Our Areas of Expertise
//                 </p>
//             </div>
//         </div>
//     </div>
//     <div className="row mt-4">
//         <div className="col-12 text-center code-logo-slider">
//             <Slider {...settings3}>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={Laravellogo}
//                         alt="LARAVEL LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={500}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={htmllogo}
//                         alt="HTML LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={900}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={phplogo}
//                         alt="PHP LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1300}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={wordpresslogo2}
//                         alt="WORDPRESS LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1600}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={reactlogo2}
//                         alt="REACT LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1800}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={adobe_xd_icon}
//                         alt="REACT LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1800}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={Bootstrap_logo}
//                         alt="REACT LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1800}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={dotnet}
//                         alt="REACT LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1800}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={Nodejs_logo}
//                         alt="REACT LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1800}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={javascriptlogo2}
//                         alt="JAVASCRIPT LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={2100}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={Laravellogo}
//                         alt="LARAVEL LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={500}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={htmllogo}
//                         alt="HTML LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={900}
//                     />
//                 </div>
//                 <div>
//                     <img
//                         className="mx-auto"
//                         src={phplogo}
//                         alt="PHP LOGO"
//                         data-aos="zoom-in-left"
//                         data-aos-duration={1300}
//                     />
//                 </div>
//             </Slider>
//         </div>
//     </div>
// </section> */}
