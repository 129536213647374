import {
  Bootstrap_logo,
  DasRealEstateBanner,
  DasRealEstateThumbnail,
  FMCSABanner,
  FMCSAThumbnail,
  htmllogo2,
  JuiceLadyBanner,
  JuiceLadyThumbnail,
  Laravellogo,
  DynamicSeamlessBanner,
  DynamicSeamlessThumbnail,
  phplogo,
  portfolioimage07,
  portfolioimage08,
  reactlogo2,
  TerryChewBanner,
  TerryChewThumbnail,
  WayneHomeDesignBanner,
  WayneHomeDesignThumbnail,
  FruitsCareAgroThumbnail,
  FruitsCareAgroBanner,
  NLPIcomThumbnail,
  NLPIcomBanner,
} from "src/assets";


export const caseStudies = [
  {
    id: "1",
    keywords: ["Learning Management System"],
    slug: "about-fmcsa-compliance-agency-llc",
    image: FMCSABanner,
    image2: FMCSAThumbnail,
    title: "FMCSA",
    description:
      "FMCSA Compliance Agency LLC assists commercial vehicle operators in meeting FMCSA regulations, ensuring compliance with mandatory safety and regulatory requirements. ",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes:
      "The client needed a user-friendly website that would streamline the registration process and make compliance regulations easier to obtain.",
    requirements: [
      "A functional, user-friendly website",
      "Easy enrollment for various training modules",
      "Effortless enrollment sections for mandatory regulatory compliance forms",
      "Dedicated video lecture sections for advanced driver and supervisor training",
      "Secure payment integration for seamless transactions",
    ],
    requirements2: [
      "UCR registration form with real-time USDOT number verification and updates.",
      "Centralized control panel for admin and super admin dashboards",
      "Contact form for effortless lead generation",
      "FAQ section to answer common queries efficiently",
    ],
    category: "Vehicle Registration",
    solution:
      "At Ditinus Technology, we developed the website from the scratch, ensuring top-tier functionality and seamless code quality. Our design focuses on user experience, allowing for easy enrollment in training modules and compliance registration forms, enabling users to sign up in just a few clicks. We incorporated secure Stripe payment integration, allowing customers to complete transactions without delays.Furthermore, our video modules for employee and supervisor training provide in-depth insights into every aspect, giving users access to learning materials with ease. We also integrated a dedicated Unified Carrier Registration (UCR) section, enabling users to check their USDOT number and verify renewal status in just a few clicks.To streamline operations, we implemented admin and super admin dashboards that facilitate efficient data management, making it easy for administrators to handle everything effortlessly.",
    images: [portfolioimage07, portfolioimage08],
    challengesHeader: "Challenges Faced",
    challengesDesc: "During the development of the website we came across several challenges that required some honing and usability testing.",

    challenges1: [
      {
        header: "UCR Validation Form with USDOT Number Verification",
        desc: "One major challenge was developing a UCR (Unified Carrier Registration) validation form where customers could input their USDOT number to verify registration status. This required real-time data retrieval and validation against the FMCSA database, ensuring accuracy and a user-friendly experience."
      },
      {
        header: "Creating Separate Dashboard Panels for Different Roles",
        desc: "Developing separate dashboard panels for Admin, User, and Supervisor roles presented another challenge. Each dashboard needed unique features and permissions specific to the requirements of each user group, ensuring secure access to relevant data."
      },
      {
        header: "Implementing Online Video Lectures with Embedded wth Question/Answer",
        desc: "We also faced the challenge of incorporating online video lectures for drivers/supervisors that concluded with True and False question/answer assessments. The system had to track user progress and provide real-time feedback on the users’ answers."
      },
    ],
    teckStackUsed: [phplogo, htmllogo2, Laravellogo, Bootstrap_logo],
    //stripe
  },

  {
    id: "2",
    keywords: ["Real Estate"],
    slug: "about-das-real-estate",
    image: DasRealEstateBanner,
    image2: DasRealEstateThumbnail,
    title: "DAS Real Estate",
    about: "DAS Real Estate deals in design, repair, and renovation services for clients in Maryland, DC, and Virginia.",
    description:
      "DAS Real Estate deals in design, repair, and renovation services for clients in Maryland, DC, and Virginia.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes:
      "The client contacted Ditinus Technology with the following requirements:",
    requirements: [
      "A responsive website that works well across different devices and screen sizes.",
      "A dedicated section for showcasing completed projects and portfolio.",
      "Links to the company’s social media handles. ",
    ],
    requirements2: [
      "A section specifically designed to detail the range of real estate services offered.",
      "An easily accessible contact form for inquiries, allowing visitors to reach out effortlessly.",
    ],
    category: "Real Estate",
    solution:
      "At Ditinus Technology we brought our client’s vision to life by building a highly intuitive and functional website from the scratch. Our skilled team utlizied the power of PHP to create a seamless online experience that perfectly meets their needs and objectives. We developed a responsive website that adapts seamlessly across all devices. A dedicated section showcases a detailed portfolio of past projects, building trust and credibility with potential clients. To strengthen online presence, we integrated links to social media handles, improving engagement. An easy-to-use contact form was implemented to streamline communication and increased lead generation. Additionally, a services section allows visitors to easily understand the range of offerings, ultimately leading to more inquiries and service bookings.",
    images: [portfolioimage07, portfolioimage08],
    challengesHeader: "Challenges Faced ",
    challengesDesc: "",
    challenges1: [
      {
        header: "Drop Shadow in Logo and Color Requirements",
        desc: "We encountered a hurdle in implementing the drop shadow effect on the logo, which led to inconsistent color rendering across different devices and browsers."
      },
      {
        header: "Parallax Image Positions and Image View",
        desc: "Achieving consistent positioning of parallax images posed another challenge.  This led to unintended cropping and variations in image visibility on various screen sizes, disrupting the visual flow."
      }
    ],
    teckStackUsed: [phplogo, Bootstrap_logo],
  },

  {
    id: "3",
    keywords: ["Shopify", "E-Commerce"],
    slug: "about-wayne-home-designs",
    image: WayneHomeDesignBanner,
    image2: WayneHomeDesignThumbnail,
    title: "Wayne Home Designs",
    about: "The company specializes in providing a wide range of home furnishings at highly affordable prices.",
    description:
      "The company specializes in providing a wide range of home furnishings at highly affordable prices.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes:
      "The client had a great website to begin with, but lacked some major gigs to improve its overall appeal. They approached Ditinus Technology to refine the user interface and enhance the user experience, ensuring it aligned better with their vision and expectations.",
    requirements: [
      "Modern design elements along wih an updated colour palette",
      "A simplified navigation structure",
    ],
    requirements2: [
      "A fully responsive website.",
      "Improved typography, and consistent styling across the website. ",
    ],
    category: "Home Furnishing",
    solution:
      "We began the process by first carefully understanding and analyzing the requirement of our client and prototyping a structure to suit their needs. We focused on improving both the aesthetics and the functionality of the website by gathering feedback to identify areas for improvement within the existing UI.  Our team crafted mockups of the new UI, showcasing features of modern design, intuitive navigation, and an updated color palette to make things pop even more. To make the website easily navigable, we introduced several key features like, reorganizing the drop down menu and adding quick-access buttons. To make the website completely responsive, we optimized the UI for several devices and screen sizes, providing a satisfactory experience to the users. Additionally, we used improved typography, and consistent styling across the website creating a more engaging user experience and improving the overall aesthetic appeal of the site.   ",
    images: [portfolioimage07, portfolioimage08],
    challengesHeader: "Challenges Faced",
    challengesDesc: "",
    challenges2: ["Redesigning the dropdown menu and adding quick-access buttons required careful planning to ensure ease of use without compromising functionality.", "Optimizing the UI for different devices and screen sizes presented challenges in maintaining a consistent user experience.", "Consistent styling and improved typography across the website required careful adjustments to improve the overall aesthetic appeal."],
    teckStackUsed: [phplogo],
  },

  {
    id: "4",
    keywords: ["Shopify", "E-Commerce"],
    slug: "about-the-juice-lady",
    image: JuiceLadyBanner,
    image2: JuiceLadyThumbnail,
    about: "Our client, The Juice Lady, offers a variety of juices designed for endurance, recovery, and detox.",
    title: "The Juice Lady ",
    description:
      "Our client, The Juice Lady, offers a variety of juices designed for endurance, recovery, and detox.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes:
      "The client approached Ditinus Technology to enhance their overall online shopping experience. ",
    requirements: [
      "They required a robust cart functionality that would allow customers to easily add and delete items.",
      "A streamlined checkout process that provided a clear price breakdown for transparency. ",
    ],
    requirements2: [
      "A secure and robust payment gateway for secure and seamless transcations.",
    ],
    category: "Beverages",
    solution:
      "To meet these needs, we developed an intuitive shopping cart system, enabling effortless management of product selections. Our team also designed a user-friendly checkout page showcasing the total costs, including taxes and discounts, building customer trust. Finally, we integrated a secure payment gateway, ensuring safe transactions and enhancing overall customer satisfaction for our client.",
    images: [portfolioimage07, portfolioimage08],
    challengesHeader: "Challenges Faced",
    challengesDesc: "",
    challenges2: ["Creating a shopping cart system that allows users to manage product selections posed challenges in ensuring usability and functionality.", "Designing a checkout page that showcases total costs, including taxes and discounts, required careful attention to precision.", "Integrating a secure payment gateway to ensure safe transactions presented challenges in maintaining security while also improving overall customer satisfaction."],
    teckStackUsed: [phplogo],
  },
  {
    id: "5",
    keywords: ["Education"],
    slug: "terry-chew-academy",
    image: TerryChewBanner,
    image2: TerryChewThumbnail,
    title: "Terry Chew Academy",
    about: "Terry Chew Academy is dedicated to making math an enjoyable and accessible subject for students. With a focus on simplicity and effectiveness, the academy transforms math learning into a rewarding experience for all students.",
    description:
      "Terry Chew Academy is dedicated to making math an enjoyable and accessible subject for students. With a focus on simplicity and effectiveness, the academy transforms math learning into a rewarding experience for all students.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes: "",
    requirements: [
      "An intuitive online learning platform for a better experience for students and parents.",
      "A great user interface for smoother and more engaging navigation.",
      "Quick loading times and reliable uptime.",
    ],
    requirements2: [
      "Accommodate a growing number of users effectively.",
      "Create a platform that encourages more students to enroll in tutoring services.",
    ],
    category: "Education",
    solution:
      "At Ditinus Technology, we executed a redesign of the client’s website, focusing on a clean, modern UI that improves usability and visual appeal. We used intuitive navigation elements, engaging visuals, and streamlined content organization to facilitate easier access to information about courses, tutors, and scheduling.  We optimized the website’s backend to enhance loading speed and reliability, employing continuous integration and deployment practices. This not only improved overall performance but also allowed for quicker updates and maintenance, ensuring the platform could effectively support the educational needs of its users.",
    images: [portfolioimage07, portfolioimage08],
    challengesHeader: "Challenges Faced",
    challengesDesc: "",
    challenges2: ["Creating intuitive navigation elements and organizing content to facilitate easy access to information about courses, tutors, and scheduling required careful planning and feedback.", "Enhancing the website's loading speed involved challenges related to integration and deployment, for easy updates and maintenance."],
    teckStackUsed: [phplogo, Laravellogo, Bootstrap_logo],
  },
  {
    id: "6",
    keywords: ["OpenCart Extension"],
    slug: "about-dynamic-seamless",
    image: DynamicSeamlessBanner,
    image2: DynamicSeamlessThumbnail,
    title: "Dynamic Seamless ",
    about: "The company specializes in B2B wholesale fashion, offering a wide range of high-quality clothing and accessories",
    description:
      "The company specializes in B2B wholesale fashion, offering a wide range of high-quality clothing and accessories.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes: "The client approached us to enhance their OpenCart functionality with the following key requirements:",
    requirements: [
      "An easy-to-use system for managing product listings.",
      "Optimized Cart Functionality.",
      "Streamlined Checkout Process for hassle free transactions. ",
    ],
    requirements2: [
      "Manage inventory with real-time updates.",
      "Buyer accounts and order tracking to track orders, view previous purchases, and manage future transactions.",
    ],
    category: "Clothing",
    solution:
      "We improved the client’s OpenCart functionality by redesigning the user interface for an advanced product management system. This made it easier to handle bulk products and pricing. We optimized the cart for flexible transactions and simplified the checkout process with a secure payment gateway. Real-time inventory and order management were added, reducing errors and enhancing operational efficiency. Additionally, these enhancements led to higher customer satisfaction, reduced cart abandonment, and increased overall business growth.",
    images: [portfolioimage07, portfolioimage08],
    challengesHeader: "Challenges Faced:",
    challengesDesc: "",
    challenges2: ["Redesigning the user interface for the advanced product management system posed challenges in balancing functionality with ease of use.", "Simplifying the checkout process while integrating a secure payment gateway required careful security measures and improved user experience.", "Adding real-time inventory and order management features presented challenges in reducing errors and ensuring operational efficiency."],
    teckStackUsed: [phplogo, Bootstrap_logo],
  },
  {
    id: "7",
    keywords: ["Agriculture"],
    slug: "about-froots-care-agro",
    about: "Fruits Care Agro are manufacturers, exporters, distributors, and suppliers of agro-related products and services across India.",
    image: FruitsCareAgroThumbnail,
    image2: FruitsCareAgroBanner,
    title: "Fruits Care Agro",
    description:
      "Fruits Care Agro are manufacturers, exporters, distributors, and suppliers of agro-related products and services across India.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes: "The client approached Ditinus Technology with the following requirements:",
    requirements: [
      "A seamless and highly intuitive website that provides detailed information on various services, agricultural products, including tools, plants, and management services.",
      "Enhance user experience that focused  on intuitive design and navigation to ensure a seamless experience for all users.",
      "Appealing graphics and visuals to engage users and effectively communicate product features and benefits.",
      "Links to social media handles and a dedicated contact us section for user queries and requirements.",
    ],
    requirements2: [

    ],
    category: "Agriculture",
    solution:
      "To address the challenges faced, we implemented a structured and organized product catalog that clearly showcased various services, reducing confusion for users. We developed a dedicated section to showcase their wide range of services, ensuring that each offering was presented in an easily understandable manner.To enhance responsiveness across devices, the team optimized the platform’s user interface(UI) and user experience(UX) for seamless navigation on any device",
    images: [FruitsCareAgroThumbnail, FruitsCareAgroBanner],
    challengesHeader: "Challenges Faced:",
    challengesDesc: "",
    challenges2: ["The complex product catalog of products and services made it challenging to create an organized, user-friendly listing that catered to diverse customer needs.", "Ensuring the platform's responsiveness across different devices while maintaining an improved user interface (UI) and user experience (UX) presented significant design and technical challenges."],
    teckStackUsed: [phplogo],
  },
  {
    id: "8",
    keywords: ["Healthcare API Development"],
    slug: "about-NLPIcom-Business",
    about: "Evgeny streamlines the sorting of medical documentation for healthcare professionals, providing healthcare management to enhance overall operations and a specialized software solution specifically for processing medical insurance claims.",
    image: NLPIcomThumbnail,
    image2: NLPIcomBanner,
    title: "NLPIcom Business",
    description:
      "Evgeny streamlines the sorting of medical documentation for healthcare professionals, providing healthcare management to enhance overall operations and a specialized software solution specifically for processing medical insurance claims.",
    services: "Cloud Migration",
    client: "Techco",
    location: "New York, NY, USA",
    completedDate: "20-12-2024",
    projectRequirement: "What Was Required?",
    requirementDes: "The medical insurance company wanted to develop a portal that could easily streamline the claims evaluation process while maintaining high precision in assessing the validity of these claims.",
    requirements: [
      "Digitization and secure storage of large volumes of medical data.",
      "Organization of the medical data in a way that made it easy to retrieve accurate information.",
      "Integration of features like NER (Named Entity Recognition) for advanced search capabilities.",
      "Advanced claim evaluation features that made it easier for evaluators to compile and summarize medical information into precise reports for healthcare professionals.",
      "Expert review integration features for healthcare professionals, ensuring direct insurance claim eligibility and approval. ",
      "Compliance of the portal with adherence to HIPAA ensuring data privacy and security standard.",
      "A quicker, more reliable claims processing, ensuring efficient disbursement of funds to policyholders."
    ],
    requirements2: [

    ],
    category: "Healthcare API Development",
    solution:
      "Our team of experts integrated (OCR) Optical Character Recognition feature that helped convert scanned documents into digital texts regardless of their format.  This data was securely stored in the NLPicom Insurance Database, which organized documents by categories like medical history, diagnosis, or treatment. We also implemented advanced search functionality with features like recommendation search, navigation search, NER combinations search, and quick keyword searches within documents.Our experts integrated an advanced automated summarization tool that could extract the most relevant data from large documents.This tool reduced the time evaluators spent compiling lengthy reports, enhancing efficiency.We also developed a machine learning algorithm to detect suspicious patterns in claim submissions, reducing the risk of fraudulent claims.Simultaneously, the portal ensured that genuine claims moved quickly through the process.",
    images: [NLPIcomThumbnail, NLPIcomBanner],
    challengesHeader: "Challenges Faced:",
    challengesDesc: "",
    challenges2: [
      "One of the initial challenges during the development of the project was that medical claim documents came in a variety of formats including scanned PDFs, digital reports, and handwritten notes. The challenge was to efficiently secure these documents and preserve accurate information.",
      "Advanced search and entity relationships paved another challenge as it was crucial that NER (Named Entity Recognition) did not only identify individual entities but also established a relationship between them.",
      "Ensuring seamless communication between multiple stakeholders was a logistical and technical challenge as it was necessary for healthcare professionals to provide review reports and communicate accurate feedback with the insurance company."],
    teckStackUsed: [reactlogo2, Bootstrap_logo],
  },
];
