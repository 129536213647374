import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "src/assets";

const Footer = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  const [isBlogs, setIsBlogs] = useState<boolean>(false);
  const fetchBlogs = async () => {
    const response = await api.get(`/blogs?page=1`);
    console.log(response);

    setIsBlogs(response?.data?.data?.length > 0);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);
  return (
    <div>
      <footer className="overflow-hidden ">
        <div className="container">
          <div className="row footer-cols-contain justify-content-evenly">
            <div className="col-xl-3 col-lg-3 col-md-12 col-12">
              <a href="/">
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "75%", maxWidth: 300, height: "auto" }}
                />
              </a>
              <small>
                Innovative solutions designed to supercharge your online
                presence! Customized for your specific needs and shaped by your
                feedback.
              </small>
            </div>
            <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-2 col-md-3 col-sm-12">
              <h5>Services </h5>
              <ul className="list-unstyled p-0">
                <li>
                  <a className="text-decoration-none transition" href="/ui-ux">
                    UI UX
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none transition"
                    href="/software-development"
                  >
                    Development
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none transition"
                    href="/digital-marketing"
                  >
                    Digital Marketing
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none transition"
                    href="/graphics"
                  >
                    Graphics
                  </a>
                </li>
              </ul>
            </div>
            <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-2 col-md-3 col-sm-12">
              <h5>Support</h5>
              <ul className="list-unstyled p-0">
                <li>
                  <a
                    className="text-decoration-none transition"
                    href="/contact-us"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none transition"
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </li>

                <li>
                  <a
                    className="text-decoration-none transition"
                    href="/terms-conditions"
                  >
                    Terms & Refund Policy
                  </a>
                </li>
              </ul>
            </div>
            {
              isBlogs &&
              <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-2 col-md-3 col-sm-12">
                <h5>Resources</h5>
                <ul className="list-unstyled p-0">
                  {/* <li>
                  <a
                    className="text-decoration-none transition"
                    href="/case-study"
                  >
                    Case Studies
                  </a>
                </li> */}
                  {/* <li>
                  <a className="text-decoration-none transition" href="/">
                    Recent Projects
                  </a>
                </li> */}
                  <li>
                    <a className="text-decoration-none transition" href="/blogs">
                      Blogs
                    </a>
                  </li>
                </ul>
              </div>
            }
            <div className="py-3 py-lg-0 py-md-2 col-xl-2 col-lg-3 col-md-3 col-sm-12 footer-address">
              <h5>Address</h5>
              <div className="col-12 d-flex flex-column gap-3">
                <div className="icon-box d-flex align-items-center">
                  <i className="bi bi-geo-alt-fill me-2 text-denim" />
                  <div className="d-flex flex-column">
                    <a href="https://www.google.com/maps/place/Ditinus+Technology+Private+Ltd.+Mohali/@30.6987635,76.6916266,15z/data=!4m2!3m1!1s0x0:0x520191891f8d816d?sa=X&ved=1t:2428&ictx=111">
                      Plot E-302, Vista Tower, Industrial Area, Sector 75,
                      Sahibzada Ajit Singh Nagar, Punjab 160055
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <hr />
          <div style={{ justifyContent: "space-between" }} className="mt-3 d-flex  align-items-center" >

            <div className="row">
              <small>
                © <span className="text-denim">Ditinus</span> All Right
                Reserved.
              </small>
            </div>
            <div className="d-flex ">

              <a href="https://www.instagram.com/ditinus/?hl=en">
                <i className="bi bi-instagram text-WH me-1 px-2 py-1" />
              </a>
              <a href="https://www.facebook.com/ditinus/">
                <i className="bi bi-facebook text-WH me-1 px-2 py-1" />
              </a>
              <a href="https://www.linkedin.com/company/ditinus-technology-pvt-ltd/?originalSubdomain=in">
                <i className="bi bi-linkedin text-WH me-1 px-2 py-1" />
              </a>

            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
