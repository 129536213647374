import {
    Hire_a_dev_wave,
    imagekpo2,
    OutSourceCardImg,
    OutsourceIMG2,
    OutsourceIMG3,
    Vector1,
    Vector10,
    Vector11,
    Vector12,
    Vector13,
    Vector14,
    Vector15,
    Vector16,
    Vector17,
    Vector18,
    Vector19,
    Vector2,
    Vector3,
    Vector4,
    Vector5,
    Vector6,
    Vector7,
    Vector8,
} from "src/assets";
import { Helmet } from "react-helmet";
import OutsourceForm from '../../Components/OutsourceForm'
import { useState } from "react";
import { motion } from 'framer-motion';
const Index = () => {
    const [isPaused, setIsPaused] = useState(false);
    return (
        <div>
            <Helmet>
                <title>
                    Business Process Services | Ditinus
                    Technology
                </title>
            </Helmet>
            <main className="bg-height4 bg-kpo-bpo">

                <section className=" " style={{ marginTop: "100px" }}>
                    <div className="container bg-danger ">
                        <div className="row jusity-content-center bg-dark">

                            <div className="bg-danger position-relative">
                                <svg
                                    width=""
                                    height="679"
                                    viewBox="0 0 1260 679"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <motion.path
                                        d="M1257.5 647C1257.5 478.079 1198.56 315.994 1078.89 196.549C959.225 77.1036 796.924 10 627.691 10C458.459 9.99999 296.157 77.1035 176.492 196.549C56.8264 315.994 1.00003 472.12 1 641.041L1257.5 647Z"
                                        fill="url(#paint0_linear_0_11685)"
                                        fillOpacity="0.07"
                                        animate={{
                                            y: [0, 10, -10, 0], // Creating a wave effect with alternating y movements
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.2, // Adds a slight delay for each path for the wave effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1259.5 637C1259.5 468.079 1200.56 305.994 1080.89 186.549C961.225 67.1036 798.924 1.27532e-05 629.691 0C460.459 -1.27532e-05 298.157 67.1035 178.492 186.549C58.8264 305.994 3.00003 462.12 3 631.041L1259.5 637Z"
                                        fill="url(#paint1_linear_0_11685)"
                                        fillOpacity="0.02"
                                        animate={{
                                            y: [0, 15, -15, 0], // Different amplitude for a subtle effect
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.4, // Adds a different delay to this path for alternating effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.5 657C1258.5 488.079 1199.56 325.994 1079.89 206.549C960.225 87.1036 797.924 20 628.691 20C459.459 20 297.157 87.1035 177.492 206.549C57.8264 325.994 2.00003 482.12 2 651.041L1258.5 657Z"
                                        fill="url(#paint2_linear_0_11685)"
                                        fillOpacity="0.12"
                                        animate={{
                                            y: [0, 20, -20, 0], // Larger amplitude for a more dramatic wave effect
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.6, // Adds a slight delay for this path for an alternating effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.5 668C1258.5 499.079 1199.56 336.994 1079.89 217.549C960.225 98.1036 797.924 31 628.691 31C459.459 31 297.157 98.1035 177.492 217.549C57.8264 336.994 2.00003 493.12 2 662.041L1258.5 668Z"
                                        fill="url(#paint3_linear_0_11685)"
                                        fillOpacity="0.18"
                                        animate={{
                                            y: [0, 10, -10, 0], // Repeating wave-like motion
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 0.8, // Slight delay for smoother alternating effect
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.5 679C1258.5 510.079 1199.56 347.994 1079.89 228.549C960.225 109.104 797.924 42 628.691 42C459.459 42 297.157 109.104 177.492 228.549C57.8264 347.994 2.00003 504.12 2 673.041L1258.5 679Z"
                                        fill="url(#paint4_linear_0_11685)"
                                        animate={{
                                            y: [0, 5, -5, 0], // Small amplitude for subtle motion
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 1, // Adds a longer delay for more contrast
                                        }}
                                    />
                                    <motion.path
                                        d="M1258.62 679.001C1258.62 596.368 1242.34 514.543 1210.72 438.2C1179.09 361.857 1132.75 292.49 1074.31 234.059C1015.88 175.628 946.517 129.279 870.173 97.6561C793.83 66.0337 712.006 49.7578 629.372 49.7578C546.739 49.7578 464.915 66.0337 388.571 97.6561C312.228 129.279 242.861 175.628 184.43 234.059C125.999 292.49 79.6496 361.857 48.0272 438.2C16.4048 514.544 0.128899 596.368 0.128906 679.001L1258.62 679.001Z"
                                        fill="#07234B"
                                        animate={{
                                            y: [0, 8, -8, 0], // Another wave pattern
                                        }}
                                        transition={{
                                            repeat: Infinity,
                                            repeatType: "loop",
                                            duration: 3,
                                            ease: "easeInOut",
                                            delay: 1.2, // Adds more delay for smoother wave effect
                                        }}
                                    />
                                    <defs>
                                        <linearGradient id="paint0_linear_0_11685" x1="628.865" y1="14.7005" x2="628.865" y2="757.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_0_11685" x1="630.865" y1="4.70049" x2="630.865" y2="747.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_0_11685" x1="629.865" y1="24.7005" x2="629.865" y2="767.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_0_11685" x1="629.865" y1="35.7005" x2="629.865" y2="778.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                        <linearGradient id="paint4_linear_0_11685" x1="629.865" y1="46.7005" x2="629.865" y2="789.535" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="white" />
                                            <stop offset="1" stopColor="white" stopOpacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className="row position-absolute col-7" style={{
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}>
                                    <h3 className="text-white text-center">
                                        Hire the Best Software
                                        Developers in India at Fraction of the Cost!
                                    </h3>
                                    <p className="text-white text-center">
                                        Did You Know? Over 50,000 businesses are already saving up to 78% on development costs by hiring skilled software experts in India!
                                    </p>
                                    <a
                                        href="#contact-form"
                                        type="button"
                                        className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                                    >
                                        Start Your Project{" "}
                                        <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="out-source-banner">
                    <div className="container out-source-fluid ">
                        <div className="row justify-content-between ">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-8 col-lg-8 text-start d-flex flex-column justify-content-center">
                                <h2 className="heading-225 ">Don’t let high costs hold you back!</h2>
                                <p className="heading-226">
                                    Reach out to us today and discover how our talented developers in India can be a game-changer for your business!
                                </p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4 d-flex justify-content-start justify-content-sm-start justify-content-md-start  justify-content-lg-end  justify-content-xl-end align-items-center">
                                <a
                                    href="#contact-form"
                                    type="button"
                                    className=" transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn px-5"
                                >
                                    Contact Us
                                    <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="out-source-sections ">
                    <div className="container">
                        <div className="justify-content-between" data-aos="fade-up">
                            <div className="img-shadow4">
                                <img loading="lazy" src={imagekpo2} className="w-100" alt="line" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="out-source-sections ">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-10 col-md-12 col-12 text-center d-flex flex-column justify-content-center align-items-center ">
                                <h2 className="heading-228 p-o m-0 col-10   col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    Let’s Get Down to Business! Explore Our Business Process Services for The Maximum Impact
                                </h2>
                                <p
                                    className="heading-227 col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 p-0 m-0 mt-3"
                                >
                                    Crush the competition and future-proof your business with our Business Process Solutions We're all about powering your success today and setting you up for an even better tomorrow!{" "}
                                </p>
                            </div>
                        </div>

                        <div className="row col-12 d-flex justify-content-center justify-content-lg-between  justify-content-xl-between  p-0 m-0 mt-5">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 mb-5 mb-sm-0 mb-lg-0 mb-xl-0 mb-md-0 mt-sm-0 mt-lg-0 mt-xl-0 mt-md-0">
                                <div className="item-source2 col-12 d-flex flex-column justify-items-start" data-aos="fade-left">
                                    <h2>1</h2>
                                    <div className="text-item2 text-start  d-flex flex-column gap-3 ">
                                        <h3 className="fw-bold">Inbound Services</h3>
                                        <p className="text-white text-start col-12  ">
                                            Your customers deserve the best, and we’re here to deliver! Our tailored inbound business process services are designed to meet the unique needs of every client. Explore our service suite and unlock maximum value today!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 m-0 img-fluid rounded-5  mt-5 mt-sm-5 mt-xl-0 mt-lg-0 mt-md-5">
                                <img className='img-fluid w-100 h-100 ' src={OutsourceIMG2} alt="" />
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector1} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Customer Support</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                Our dedicated customer support team offers around the clock assistance, delivering solutions that go above and beyond expectations. We ensure your customers feel heard, valued, and fully supported at every step. From answering inquiries to resolving issues and offering expert product guidance, we create smooth experiences that drive customer loyalty and satisfaction.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector2} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Chat Support</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                Say goodbye to delayed customer support with our inbound services. Our live chat support ensures instant assistance, providing quick responses and efficient problem resolution. No matter the query, we deliver a prompt, personalized experience that meets your customers’ needs, every time.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector3} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Email Support</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                Want to make a lasting impact on your audience? With our email support services, you can deliver prompt, clear responses to customer queries, complaints, and feedback. We focus on accuracy and attention to detail, ensuring every email interaction leaves a positive and lasting impression on your customers.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="row col-12 d-flex justify-content-center p-0 m-0 mt-5">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8  mb-5 mt-10 mb-sm-0 mb-lg-0 mb-xl-0 mb-md-0 mt-sm-0 mt-lg-0 mt-xl-0 mt-md-0">
                                <div className="item-source2 col-12 d-flex flex-column justify-items-start" data-aos="fade-left">
                                    <h2>2</h2>
                                    <div className="text-item2 text-start  d-flex flex-column gap-3 ">
                                        <h3 className="fw-bold">Outbound Services</h3>
                                        <p className="text-white text-start col-12  ">
                                            Expand your business reach and effectively connect with your target audience through our powerful suite of outbound business process services! Unlock new opportunities and drive growth every step of the way!
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-0 m-0 img-fluid rounded-5  mt-5 mt-sm-5 mt-xl-0 mt-lg-0 mt-md-5">
                                <img className='img-fluid w-100 h-100 ' src={OutsourceIMG3} alt="" />
                            </div>

                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector4} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Sales</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                Reach your targets and optimize your sales prospects with ease with our outbound sales services. We use proven strategies to highlight your brand’s core competency, build meaningful relationships, and close deals, ensuring consistent revenue growth and long-term client satisfaction.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector5} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Lead Generation and Qualification</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                Our expert lead generation services are designed to deliver only the highest-quality leads to you. Our rigorous filtering process ensures that only premium clients with strong conversion potential reach your sales team, maximizing your revenue opportunities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector6} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Customer Retention and Win-Back Campaigns</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                Every contact matters, and every customer counts. Our strategic customer retention and win-back campaigns focus on unlocking the potential of even the most lost leads. We build loyalty and reconnect with lapsed customers, transforming them into repeat buyers.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector7} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Surveys and Feedback Collection</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                Gain valuable insights directly from your customers with our surveys and feedback collection outbound services. By understanding their needs and preferences we help you make data-driven decisions, improve satisfaction, and continuously enhance your product or service offerings.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="bg-color-box2"
                                    data-aos="fade-up"
                                    data-aos-duration={500}
                                >
                                    <div className="row">
                                        <div className="col-lg-1 col-md-2 col-sm-4 col-xl-1 col-3 d-flex justify-content-sm-start justify-content-center justify-content-lg-center justify-content-md-center align-items-start align-items-lg-center align-items-xl-center  align-items-md-center ">
                                            <img loading="lazy" className="img-fluid " src={Vector8} alt="line" />
                                        </div>
                                        <div className="col-lg-11 col-md-10 col-sm-8 col-xl-11 col-9 d-flex flex-column  align-items-start justify-content-center">
                                            <h3 className="p-0 m-0 fw-bold text-start outsource-card-999">Appointment Setting</h3>
                                            <p className="text-white p-0 m-0 mt-3 fw-light">
                                                We make appointment setting easy by scheduling qualified meetings with your clients. Our organized approach allows your team to spend less time on coordination and more time on building meaningful conversations, resulting in closed deals and better relationships.
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>

                <section className="out-source-sections">
                    <div className="container mt-5">
                        <div className="row col-12 p-0 m-0 ">
                            <div className="col-12 col-md-12 col-xl-12 col-lg-12 col-sm-12 d-flex flex-column justify-content-center gap-3">
                                <h2 className="heading-231 text-start p-0 m-0 col-12">
                                    We Offer Custom Solutions for Every Industry!
                                </h2>
                                <p className="text-white p-0 m-0 text-start heading-232 fw-light col-11">
                                    Our Business Process Solutions are not confined to a single industry. Our expertise spans several key sectors, driving innovation and sustainable growth across the board.
                                </p>
                            </div>
                            <div className="col-12">
                                <h3 className="text-white p-0 m-0 card-middle-test text-uppercase text-start py-3">Industries we Serve!</h3>
                            </div>

                            <div className="col-12 col-md-12 mt-5 mt-sm-5 mt-lg-0 mt-xl-0 mt-md-5 col-xl-12 col-lg-12 col-sm-12 p-0 m-0 d-flex flex-row flex-sm-row flex-lg-column flex-xl-col flex-md-row align-items-center justify-content-evenly gap-5 gap-sm-5 gap-md-5 gap-lg-5 gap-xl-5">

                                <div className="col-6 col-sm-6 col-lg-12 col-xl-12 col-md-6 row d-flex flex-column flex-sm-column flex-lg-row flex-xl-row flex-md-column gap-md-4 gap-3 gap-lg-5 gap-xl-5 gap-sm-3   align-items-end">
                                    <div className="col-5  out-source-card1" data-aos='fade-left'>
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-right" loading="lazy" className="img-fulid" src={Vector13} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Agriculture</h4>
                                        </div>
                                    </div>
                                    <div className="col-5  out-source-card5" data-aos='fade-left' >
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-right" loading="lazy" className="img-fulid" src={Vector11} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Logistics</h4>
                                        </div>
                                    </div>
                                    <div className="col-5  out-source-card5"  >
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-left" loading="lazy" className="img-fulid" src={Vector10} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Travel</h4>
                                        </div>
                                    </div>
                                    <div className="col-5  out-source-card5" data-aos='fade-right' >
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-left" loading="lazy" className="img-fulid" src={Vector12} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Information Technology</h4>
                                        </div>
                                    </div>
                                    <div className="col-5  out-source-card2" data-aos='fade-right' >
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-left" loading="lazy" className="img-fulid" src={Vector14} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Healthcare &<br /> Pharmaceuticals</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 col-sm-6 col-lg-12 col-xl-12 col-md-6 row d-flex flex-column flex-sm-column flex-lg-row flex-xl-row flex-md-column gap-md-4 gap-3 gap-lg-5 gap-xl-5 gap-xxl-5 gap-sm-3 ">
                                    <div className="col-5 out-source-card3" data-aos='fade-left'>
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-right" loading="lazy" className="img-fluid" src={Vector15} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center text-nowrap">E-commerce &<br /> Retail</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 out-source-card5" data-aos='fade-left'>
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-left" loading="lazy" className="img-fluid" src={Vector16} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Telecom</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 out-source-card5" >
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-left" loading="lazy" className="img-fluid" src={Vector17} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Real Estate</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 out-source-card5" data-aos='fade-right'>
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-left" loading="lazy" className="img-fluid" src={Vector18} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center text-nowrap">Education &<br /> E-learning</h4>
                                        </div>
                                    </div>
                                    <div className="col-5 out-source-card4" data-aos='fade-right'>
                                        <div className="h-50 d-flex justify-content-center align-items-center">
                                            <img data-aos="flip-left" loading="lazy" className="img-fluid" src={Vector19} alt="" />
                                        </div>
                                        <div className="h-25 d-flex justify-content-center align-items-center">
                                            <h4 className=" out-source-card-text text-white p-0 m-0 text-center">Automotive</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>

                {/* <section className="out-source-sections-slide">
          <div className="container">
            <div className="row col-12 p-0 m-0 ">
              <div className="col-12 col-md-12 col-xl-6 col-lg-6 col-sm-12 d-flex flex-column justify-content-center gap-3">
                <h2 className="heading-231 text-start p-0 m-0 col-12">
                  Client Satisfaction Is Our Primary Focus
                </h2>
              </div>
            </div>
          </div>
        </section>
        <Marquee
          pauseOnHover
          // onMouseEnter={() => setIsPaused(true)}
          // onMouseLeave={() => setIsPaused(false)}
          // pause={isPaused}
          className=" mb-5"
        >
          <div className="d-flex flex-row">
            {reviewData.map((_, index) => (
              <div className="p-3" key={index}>
                <div className="client-review-content">
                  <div className="client-review-img d-flex">
                    <img loading="lazy" src={_.clientImage} alt="img" />
                  </div>
                  <div className="client-review-stars">
                    <img loading="lazy" src={star} alt="img" />
                    <img loading="lazy" src={star} alt="img" />
                    <img loading="lazy" src={star} alt="img" />
                    <img loading="lazy" src={star} alt="img" />
                    <img loading="lazy" src={star} alt="img" />
                  </div>
                  <div>
                    <h5 className="text-white p-0 m-0">{_.reviewTitle}</h5>
                  </div>
                  <div className="client-review-desc">
                    <small className="text-white p-0 m-0">
                      {_.reviewDesc}
                    </small>
                    <div className="review-title">

                      <h5 className="text-white p-0 m-0 mt-3 text-center">{_.author}</h5>

                    </div>
                  </div>
                </div>
              </div>))}
          </div>
        </Marquee> */}

                <section className="out-source-banner2">
                    <div className="container out-source-fluid2">
                        <div className="column col-12 text-center d-flex flex-column justify-content-center align-items-start align-items-sm-start align-items-lg-center align-items-xl-center align-items-md-start">
                            <div className="col-12 d-flex justify-content-center align-items-center flex-column">
                                <h1 className="heading-225 col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 text-start text-sm-start text-md-start text-xl-center text-lg-center">Ready to revolutionize your game?</h1>
                                <p className="heading-226 col-12 col-sm-12 col-lg-10 col-xl-10 col-md-12 text-start text-sm-start text-md-start text-xl-center text-lg-center ">
                                    Outsource your business needs to us and watch your goals shoot for the stars in no time!
                                </p>
                            </div>

                            <div className="col-8 col-sm-8 col-md-6 col-xl-2 col-lg-2 d-flex justify-content-start justify-content-sm-start justify-content-md-start  justify-content-lg-center  justify-content-xl-center align-items-center">
                                <a
                                    href="#contact-form"
                                    type="button"
                                    className=" transition  text-nowrap rounded-pill w-100 d-flex justify-content-center fill-btn outline-btn2 iconBtn"
                                >
                                    Consult today
                                    <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="out-source-sectionsFinal ">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-11 col-md-12 col-12 d-flex flex-column justify-content-center align-items-center ">
                                <h2 className="heading-233 text-center col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12">
                                    Why Outsource Your Business Needs To Us?
                                </h2>
                                <p className=" p-0 m-0 heading-234 text-white mb-5 text-center col-12 col-sm-12 col-lg-7 col-xl-7 col-md-10">
                                    Explore the benefits of outsourcing your business needs to the best Business Process Solutions company, where you get a powerful combination of innovation, technology, and cost-effectiveness all under one roof.
                                </p>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 px-4">
                                <ul className="d-flex flex-column gap-5 p-0 m-0 pr-3">
                                    <li className="text-white">
                                        <p className="p-0 m-0 text-white">
                                            <strong className="fw-bold">Expertise and Skill - {" "}
                                            </strong>
                                            Take advantage of our specialized skills and knowledge in handling various business operations with our dynamic and scalable business process services, ensuring your goals are met efficiently.
                                        </p>
                                    </li>
                                    <li className="text-white">
                                        <p className="p-0 m-0 text-white">
                                            <strong className="fw-bold">  Cost-Effective Solutions - {" "}
                                            </strong>
                                            Save big on business operations by outsourcing tasks to us, eliminating the need for expensive in-house resources, infrastructure, and overhead expenses.
                                        </p>
                                    </li>
                                    <li className="text-white">
                                        <p className="p-0 m-0 text-white">
                                            <strong className="fw-bold">Focus on Main Business Operations - {" "}
                                            </strong>
                                            Forget about handling every task yourself! Our business process services help you focus on your core business operations while we manage non-core functions efficiently.
                                        </p>
                                    </li>
                                    <li className="text-white">
                                        <p className="p-0 m-0 text-white">
                                            <strong className="fw-bold">Scalability - {" "}
                                            </strong>
                                            Easily scale your business operations with our outsourcing services based on fluctuating demands, without the hassle of hiring, training, or managing additional staff.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 mt-5 mt-sm-5 mt-lg-0 mt-xl-0 mt-md-5 ">
                                <img src={OutSourceCardImg} className="img-fluid w-100 h-100" alt="" />
                            </div>
                        </div>

                    </div>
                </section>

                <OutsourceForm />

            </main>
        </div>
    );
};

export default Index;
