import React from "react";
import { Helmet } from "react-helmet";

const Index = () => {
  return (
    <>
      <Helmet>
        <title>
          Terms and Conditions{" "} | Ditinus
          Technology
        </title>
      </Helmet>
      <div>
        <div>
          <main className="bg-height4 bg-kpo-bpo blog-list1 career-page">
            <section className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 text-center top-banner">
                  <h1 className="heading-01">Terms of Use & Refund Policy</h1>
                  <h6 className="text-white mt-3">
                    last Updated : October 2024
                  </h6>
                </div>
              </div>
            </section>
          </main>
        </div>
        <section className="form-list-career text-WH terms15">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                  <h3 className="text-WH">Terms</h3>
                </div>
                <p>
                  Welcome to Ditinus Technology Pvt. Ltd.  (“Company”, “we”, “us”, or “our”). By accessing or using our services and website (the “Services”), you (“User”, “you”, or “your”) agree to comply with and be bound by these Terms of Use (“Terms”). If you do not agree with these Terms, please do not use our Services.
                  <br />
                  The Agreement will begin on the date the Client enrolls in Ditinus Technology Pvt. Ltd. Services and will continue until terminated by either party in accordance with the Terms & Conditions.

                </p>
                <div className="row px-2">
                  <ol className="">
                    <li className=" fw-bold fs-large mb-3">
                      Acceptance of Terms
                    </li>
                    <p>
                      By using our Services, you represent that you are at least 18 years of age. If you are using the Services on behalf of a business, you represent that you have the authority to bind that business to these Terms.
                    </p>
                    <li className=" fw-bold fs-large mb-3">Changes to these Terms of Use</li>
                    <p>
                      We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on our website. Your continued use of the Services after any changes indicates your acceptance of the new Terms. Please review these Terms periodically for updates.
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      Description of Services Provided
                    </li>
                    <p>
                      Ditinus Technology Pvt. Ltd. offers a range of IT services, including but not limited to:
                      <ul className="mt-3">
                        <li>Software Development</li>
                        <li>IT Consulting</li>
                        <li>Business Process Services</li>
                        <li>Agent Based Services</li>
                        <li>Digital Marketing</li>
                        <li>Design and Development</li>
                        <li>Application API and Database</li>
                      </ul>
                      <br />
                      Details of specific services can be found on our website or provided during consultations. Unless explicitly stated otherwise, any new feature that enhances or supplements the Services will be considered part of Ditinus Technology Pvt. Ltd. Services. The Company reserves the right to modify, suspend, or discontinue the Services (or any portion thereof) at any time, without notice, in the event of non-cooperation, non-payment, or unnecessary delays by the Client. The Client agrees that neither they nor any related third party will hold the Company or its suppliers liable for any losses, damages, or consequences arising from such modifications, suspensions, or discontinuations of the Services.
                    </p>

                    <li className=" fw-bold fs-large mb-3">
                      Access To Information
                    </li>
                    <p>
                      For the purposes of this Agreement, all web pages owned, operated, or hosted by the Company, or on behalf of the Company, are collectively referred to as the "Company Websites." To access the Company Services or the Company Websites, the Client may be required to provide certain registration details or other information. It is a condition of the Client's use of the Services or access to the Company Websites that all information provided is accurate, up-to-date, and complete. If the Company believes that the information provided by the Client is not accurate, current, or complete, the Company reserves the right to deny the Client access to any Company Websites or Services, and to suspend or terminate the Client's account at any time.
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      Payment Terms
                    </li>
                    <ol>
                      <li className=" fw-bold fs-large mb-3">Payment For Web Developement Services:</li>
                      <p>If the client chooses to utilize our web development services, they are required to make payments according to the milestones outlined in the contractual agreement.
                        <br />
                        <ul>
                          <li>30% due at the start of the project</li>
                          <li>20% due upon completion of the first milestone</li>
                          <li>20% due upon completion of the second milestone</li>
                          <li>30% due upon final delivery and approval of the project</li>
                        </ul>
                      </p>
                      <li className=" fw-bold fs-large mb-3">Payment for Business Process Services:</li>
                      <p>When a client opts for our Business Process Services, payment is typically structured on a per-seat basis. This means that clients will be charged according to the number of seats or licenses they require for their operations. Additionally, we require a two-month advance security deposit to ensure a smooth onboarding process and to cover initial operational costs. This payment structure allows us to provide tailored support that aligns with the specific needs of your business while maintaining flexibility as your requirements evolve.
                        <br />
                        Our transparent pricing model is designed to help you budget effectively and maximize the value of our services.
                      </p>
                      <li className=" fw-bold fs-large mb-3">Cost Structure for Agent Based Services:</li>
                      <p>When you wish to access our Agent as a Service module, we provide flexible payment options to suit your needs. You can choose a monthly fee for each agent for predictable budgeting and continuous support. We also offer hourly rates for temporary help or project-based fees for specific tasks. This flexibility allows you to pick the payment method that works best for your project and budget while ensuring you receive excellent service from our dedicated agents.</p>

                      <h5 className="m-0 p-0 mb-2">Payment Methods:</h5>
                      <p>Clients can choose from a variety of payment methods, including but no limited to:</p>
                      <ul>
                        <li>Bank Transfer</li>
                        <li>PayPal</li>
                        <li>Credit Cards</li>
                        <li>Debit Cards</li>
                        <li>UPI (Unified Payments Interface)</li>
                      </ul>
                      <h6 className=" mt-3">Refund Policy</h6>
                      <p>
                        To maintain quality and alignment with client needs, we implement a rigorous approval process. Clients will have the opportunity to review and approve each project milestone before we proceed to the next stage.
                        <br />
                        Once a project has been delivered and approved by the client, a refund is not applicable. This is due to the fact that services have been provided and accepted as per the agreed-upon milestones.
                      </p>
                    </ol>

                    <li className=" fw-bold fs-large mb-3">
                      Proprietary Information
                    </li>
                    <p>
                      The material and content available on this site, as well as any other websites owned, operated, licensed, or controlled by the Company (collectively referred to as the "Content"), are proprietary information belonging to the Company or the third party that provided the Content. The Company and its content providers retain all rights, title, and interest in the Content.
                      <br />
                      The Content may not be copied, distributed, republished, uploaded, posted, or transmitted in any form without prior written consent from the Company, except that Clients may print a copy of the Content for internal use only. Clients are prohibited from removing or altering, or causing the removal or alteration of, any copyright, trademark, trade name, service mark, or other proprietary notices appearing on the Content.
                      <br />
                      Modification or use of the Content outside of what is expressly permitted in these Terms and Conditions is not allowed. Access to the Services or Company Websites does not transfer any title or intellectual property rights to the Client.
                      <br />
                      Furthermore, nothing in this Agreement restricts the Company’s suppliers from providing their Services, Company Websites, or technology to any third party for any purpose, nor does it affect the rights granted to those third parties. The use of any Services or Company Websites by users does not grant or confer any intellectual property rights to them.
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      Prohibited Activities
                    </li>
                    <p>
                      You agree not to engage in any of the following prohibited activities:
                      <ul className="mt-3">
                        <li>Using the Services for any illegal or unauthorized purpose.</li>
                        <li>Interfering with or disrupting the security, integrity, or performance of the Services.</li>
                        <li>Attempting to gain unauthorized access to the Services or its related systems or networks.</li>
                        <li>Transmitting any viruses, malware, or harmful code.</li>
                      </ul>
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      Submissions
                    </li>
                    <p>
                      The Client grants the Company a royalty-free, perpetual, irrevocable, worldwide, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display all content, comments, suggestions, ideas, graphics, or other information submitted to the Company through this site (collectively referred to as the “Submission”). The Company may also incorporate any Submission into other works in any form, media, or technology, whether currently known or developed in the future.
                      <br />
                      The Company is not obligated to treat any Submission as confidential and may use it in its business, including for products or advertising, without incurring any liability for royalties or other considerations. The Company will not be liable for any similarities that may arise in its future operations.
                      <br />
                      The Company and its suppliers do not claim ownership rights over any Content that the Client owns or that is owned by a third party, which the Client has provided for inclusion in the Services or integration onto the Client's website. Therefore, the Client is solely responsible for ensuring that such Content complies with Company policies and does not infringe on any rights. Any personal information submitted by the Client will be handled in accordance with the Company’s Privacy Policy.
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      Disclaimer of Warranties
                    </li>
                    <p>
                      Our Services are provided on an “as-is” and “as-available” basis. Ditinus Technology Pvt. Ltd. makes no warranties or representations, express or implied, regarding the accuracy, reliability, or availability of our Services.
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      Limitation of Liability
                    </li>
                    <p>
                      To the fullest extent permitted by law, Ditinus Technology Pvt. Ltd. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits or revenues, data loss, or business interruption arising out of or related to your use of the Services.
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      Indemnification
                    </li>
                    <p>
                      You agree to indemnify, defend, and hold harmless , Ditinus Technology Pvt. Ltd. its affiliates, and their respective officers, directors, employees, and agents from any claims, losses, liabilities, damages, costs, or expenses (including reasonable attorney fees) arising out of or related to your use of the Services or violation of these Terms.
                    </p>
                    <li className=" fw-bold fs-large mb-3">
                      External Sites
                    </li>
                    <p>
                      This site may contain hyperlinks to external websites that are not maintained by or affiliated with the Company. These hyperlinks are provided as a convenience to users and do not imply sponsorship or endorsement by the Company.

                      The Company has not reviewed all of these external sites and is not responsible for their content. Accessing these hyperlinks is at the Client’s own risk, and the Company makes no representations or warranties regarding the content, completeness, or accuracy of the hyperlinks or the sites they connect to. Furthermore, the inclusion of a hyperlink to a third-party site does not imply the Company’s endorsement of that site.

                    </p>
                    <li className=" fw-bold fs-large mb-3">Contact Information</li>
                    <p>
                      If you have any questions or concerns about these Terms/Refund Policy, please contact us at: info@ditinustechnology.com
                    </p>
                  </ol>
                </div>
                {/* <div className="row">
                  <h5 className="text-WH mt20">Disclaimer:</h5>
                  <p>
                    The materials on Ditinus Technology Pvt. Ltd.’s web site are
                    provided “as is”. Ditinus Technology Pvt. Ltd. makes no
                    warranties, expressed or implied, and hereby disclaims and
                    negates all other warranties, including without limitation,
                    implied warranties or conditions of merchantability, fitness
                    for a particular purpose, or non-infringement of intellectual
                    property or other violation of rights. Further, Ditinus
                    Technology Pvt. Ltd. does not warrant or make any
                    representations concerning the accuracy, likely results, or
                    reliability of the use of the materials on its Internet web
                    site or otherwise relating to such materials or on any sites
                    linked to this site.
                  </p>
                </div>
                <div className="row">
                  <h5 className="text-WH mt20">Limitations:</h5>
                  <p>
                    In no event shall Ditinus Technology Pvt. Ltd. or its
                    suppliers be liable for any damages (including, without
                    limitation, damages for loss of data or profit, or due to
                    business interruption,) arising out of the use or inability to
                    use the materials on Ditinus Technology Pvt. Ltd.’s Internet
                    site, even if Ditinus Technology Pvt. Ltd. or a Ditinus
                    Technology Pvt. Ltd. authorized representative has been
                    notified orally or in writing of the possibility of such
                    damage. Because some jurisdictions do not allow limitations on
                    implied warranties, or limitations of liability for
                    consequential or incidental damages, these limitations may not
                    apply to you.
                  </p>
                </div>
                <div className="row">
                  <h5 className="text-WH mt20">Revision &amp; Errata:</h5>
                  <p>
                    The materials appearing on Ditinus Technology Pvt. Ltd.’s web
                    site could include technical, typographical, or photographic
                    errors. Ditinus Technology Pvt. Ltd. does not warrant that any
                    of the materials on its web site are accurate, complete, or
                    current. Ditinus Technology Pvt. Ltd. may make changes to the
                    materials contained on its web site at any time without
                    notice. Ditinus Technology Pvt. Ltd. does not, however, make
                    any commitment to update the materials.
                  </p>
                </div>
                <div className="row">
                  <h5 className="text-WH mt20">Links:</h5>
                  <p>
                    Ditinus Technology Pvt. Ltd. may revise these terms of use for
                    its web site at any time without notice. By using this web
                    site you are agreeing to be bound by the then current version
                    of these Terms and Conditions of Use.
                  </p>
                </div>
                <div className="row">
                  <h5 className="text-WH mt20">
                    Site Terms of Use Modifications:
                  </h5>
                  <p>
                    Any claim relating to Ditinus Technology Pvt. Ltd.’s web site
                    shall be governed by the laws of the State of Punjab without
                    regard to its conflict of law provisions. General Terms and
                    Conditions applicable to Use of a Web Site.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </section >
      </div >
    </>
  );
};

export default Index;
