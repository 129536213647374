import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link, useLocation } from "react-router-dom";

const FloatingMedia = ({ scrollThreshold = 100 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    if (location.pathname === '/') {
      window.addEventListener('scroll', handleScroll);
      setIsVisible(window.scrollY > scrollThreshold);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setIsVisible(true);
      return undefined;
    }
  }, [scrollThreshold, location]);

  useEffect(() => {
    if (isVisible) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isVisible, controls]);

  return (
    <motion.div
      className="position-fixed bottom-0 end-0 mb-3 me-3 w-auto "
      style={{ zIndex: "9999" }}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <ul className="list-unstyled p-0 m-0">
        <ul className="list-unstyled p-0 m-0">
          <li className="mb-2">
            <Link
              to="https://wa.me/+919915578189"
              className="btn btn-outline-primary rounded-circle fill-btn outline-btn d-flex justify-content-center align-items-center"
              style={{ height: "50px", width: "50px" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="bi bi-whatsapp" style={{ fontSize: "22px" }}></i>
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="tel:+919915578189"
              className="btn btn-outline-primary rounded-circle fill-btn outline-btn d-flex justify-content-center align-items-center"
              style={{ height: "50px", width: "50px" }}
            >
              <i
                className="bi bi-telephone-fill"
                style={{ fontSize: "22px" }}
              ></i>
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="mailto:business@ditinustechnology.com"
              className="btn btn-outline-primary rounded-circle fill-btn outline-btn d-flex justify-content-center align-items-center"
              style={{ height: "50px", width: "50px" }}
            >
              <i
                className="bi bi-envelope-fill"
                style={{ fontSize: "22px" }}
              ></i>
            </Link>
          </li>
        </ul>
      </ul>
    </motion.div>
  );
};

export default FloatingMedia;
