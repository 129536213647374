import Slider from "react-slick";
import {
  bottomline,
  brand01,
  figam1,
  inviso1,
  uiimg,
  uiimg02,
  uiimg03,
  ux01,
  ux02,
  ux03,
  ux04,
  ux05,
  ux06,
  ux07,
  ux08,
  ux09,
  xd01,
} from "src/assets";
import { Helmet } from "react-helmet";

const Index = () => {
  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div>
      <Helmet>
        <title>
          UI/UX Design Service | Ditinus
          Technology
        </title>
      </Helmet>
      <main className="bg-height4 bg-ui-ux">
        <section className="subBanner">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 text-center top-banner">
                <h1 className="heading-01">UI/UX Design Services</h1>
                <div className="row justify-content-center mt-4">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="p-text">
                      To make your business visually appealing our UI/UX design
                      services in India ensure your customers have a great time
                      interacting with your website or app.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center d-flex justify-content-center mt-3 mb-5">
                <a
                  href="#contact-form"
                  className=" transition text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
                >
                  Start Your Project{" "}
                  <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <div className="row g-3" data-aos="fade-up">
              <div className="col-12">
                <h2 className="heading-02 lh-sm">
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                  >
                    Why Choose Us
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-6 col-lg-6 col-12 col-md-12 d-flex justify-content-start">
                <h4 className="heading-08 text-WH" style={{ fontSize: "26px", fontWeight: "400" }}>
                  Comprehensive UX/UI Design Services in India
                </h4>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center">
                <p className=" text-white " style={{ fontSize: "18px", fontWeight: "200" }}>
                  Our approach revolves around putting users at the heart of
                  every design decision. We conduct in-depth research, analyze
                  user behavior, and create detailed user personas to ensure our
                  designs align with your target audience’s preferences and
                  expectations.
                </p>
              </div>
            </div>
            <div className="row iconCardBox-container">
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={ux03} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    User Research and Analysis
                  </h5>
                  <p className="text-gray text-center">
                    We delve into user insights, conduct usability testing, and
                    gather feedback to inform our design process and create
                    user-focused solutions
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={ux07} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Information Architecture
                  </h5>
                  <p className="text-gray text-center">
                    We expertly organize and structure content, ensuring
                    seamless navigation and logical flow throughout your digital
                    platform
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={ux09} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Wireframing and Prototyping
                  </h5>
                  <p className="text-gray text-center">
                    Our designers create wireframes and interactive prototypes
                    that visualize the user interface, allowing for early
                    testing and feedback
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={ux08} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Visual Design
                  </h5>
                  <p className="text-gray text-center">
                    We bring your brand to life with visually stunning
                    interfaces, using a harmonious blend of colors, typography,
                    and graphics that resonate with your target audience
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={ux01} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Interaction Design
                  </h5>
                  <p className="text-gray text-center">
                    We design intuitive interactions and micro interactions that
                    enhance user engagement, delighting them at every step
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                <div
                  className="iconCardBox"
                  data-aos="fade-up"
                  data-aos-duration={500}
                >
                  <div
                    className="img-icon text-center"
                    data-aos="fade-left"
                    data-aos-duration={1000}
                  >
                    <img loading="lazy" src={ux02} alt="icon" className="mx-auto" />
                  </div>
                  <h5 className="heading-08 text-WH text-center">
                    Usability Testing &amp; Optimization
                  </h5>
                  <p className="text-gray text-center">
                    We conduct rigorous usability testing and iterate designs to
                    optimize user experiences, ensuring your digital product
                    exceeds expectations
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 text-center d-flex justify-content-center mt-4">
              <a
                href="#contact-form"
                className="mt-4 transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn"
              >
                Let’s get started{" "}
                <i className="bi bi-arrow-up-short ms-1 fs-3 w-fit" />
              </a>
            </div>
          </div>
        </section>
        <section className="design-wth-icon">
          <div className="container">
            <div className="row g-3 justify-content-center gap5">
              <div className="col-lg-5 col-md-10 col-sm-12">
                <div className="card-icon right-top" data-aos="fade-up">
                  <div className="row">
                    <div className="img-icon">
                      <img loading="lazy" src={ux06} />
                    </div>
                  </div>
                  <div className="row">
                    <h3>User Experience&nbsp;–&nbsp;Maximized</h3>
                    <p>
                      We design user-friendly interfaces and intuitive
                      interactions. This approach enhances customer satisfaction
                      and engagement, driving loyalty and increasing
                      conversions, ultimately ensuring competitive edge in the
                      tech industry.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-10 col-sm-12">
                <div className="card-icon let-top" data-aos="fade-up">
                  <div className="row">
                    <div className="img-icon">
                      <img loading="lazy" src={ux05} />
                    </div>
                  </div>
                  <div className="row">
                    <h3>Designing with&nbsp;Users in Mind</h3>
                    <p>
                      We focus on creating intuitive and engaging experiences
                      tailored to meet the needs and preferences of our clients.
                      By prioritizing user feedback and behavior, we enhance
                      usability, satisfaction, and drive loyalty, ultimately
                      leading to more successful products and services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="image-text-box">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="heading-02 lh-sm text-center text-WH">
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration={900}
                  >
                    Our Case Studies
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
                <p
                  className="text-WH mt-3"
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  Learning Management System
                </p>
              </div>
              <div className="col-xl-10 mtop5 text-center">
                <p
                  className="text-WH"
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  With the aim of assisting students to excel in their NCLEX-RN
                  nursing licensing exams in Canada, our client needed a web
                  application that can ensure intensive studies with the right
                  study materials, effective study pattern and self evaluation
                  assessment tasks.
                </p>
              </div>
            </div>
            <div
              className="row g-3 matop"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                <img loading="lazy" className="img-fluid" src={uiimg} />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                <h3>Distinct questions for each test.</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>The app focused on usability.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Self-evaluation tests repeated questions.</p>
                  </div>
                </div>
                <h3>Solution</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Unique question sets were used.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>This prevents repetition and tracks progress.</p>
                  </div>
                </div>
                <h3>Outcome</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Questions dynamically change each session.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Students can repeatedly evaluate their preparation.</p>
                  </div>
                </div>
                <div className="col-12  d-flex justify-content-start">
                  {/* <a
                    href="#"
                    className="mt-4 transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn px-4"
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </div>
            <div
              className="row g-3 matop revers-col"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                <h3>Resume Test Feature</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Usability issue: test interruptions.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Solution needed for resuming tests.</p>
                  </div>
                </div>
                <h3>Process</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Test can be paused and resumed.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Continue tests anytime without technical issues.</p>
                  </div>
                </div>
                <h3>Outcome</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>No need to restart tests; just resume.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Timer pauses and resumes with the test.</p>
                  </div>
                </div>
                <div className="col-12  d-flex justify-content-start">
                  {/* <a
                    href="#"
                    className="mt-4 transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn px-4"
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                <img loading="lazy" className="img-fluid" src={uiimg02} />
              </div>
            </div>
            <div
              className="row g-3 matop"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                <img loading="lazy" className="img-fluid" src={uiimg03} />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 pading-img">
                <h3>Integrating Custom Control</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Recorded lessons for missed classes.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Video controls were missing, complicating navigation.</p>
                  </div>
                </div>
                <h3>Process</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Developed video controller to enhance usability.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Added subtitles for better content comprehension.</p>
                  </div>
                </div>
                <h3>Outcome</h3>
                <div className="row">
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Smooth video revisits with controls.</p>
                  </div>
                  <div className="col-1">
                    <img loading="lazy" className="img-fluid" src={ux04} />
                  </div>
                  <div className="col-11">
                    <p>Subtitles improve study and understanding.</p>
                  </div>
                </div>
                <div className="col-12  d-flex justify-content-start">
                  {/* <a
                    href="#"
                    className="mt-4 transition  text-nowrap rounded-pill fill-btn outline-btn2 iconBtn px-4"
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="logoSlider-section logoSlider-aboutPage">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 ">
                <h2 className="heading-02 lh-sm text-center text-WH">
                  The Things{" "}
                  <span
                    className="position-relative d-inline-block"
                    data-aos="fade-left"
                    data-aos-duration={900}
                  >
                    We Do Best
                    <img
                      src={bottomline}
                      alt="line"
                      className="position-absolute start-0 top-100 text-bottom-line w-100"
                    />
                  </span>
                </h2>
              </div>
              <div className="col-xl-8 col-lg-12 mtop text-center">
                <p
                  className="text-white fs-5 "
                  data-aos="fade-up"
                  data-aos-duration={900}
                >
                  Our Areas of Expertise
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center code-logo-slider">
              <Slider {...settings2}>
                <div>
                  <img
                    className="mx-auto"
                    src={figam1}
                    alt="figma"
                    data-aos="zoom-in-left"
                    data-aos-duration={500}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={inviso1}
                    alt="inviso1"
                    data-aos="zoom-in-left"
                    data-aos-duration={900}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={brand01}
                    alt="brand01"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
                <div>
                  <img
                    className="mx-auto"
                    src={xd01}
                    alt="xd01"
                    data-aos="zoom-in-left"
                    data-aos-duration={1300}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Index;
