import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { icontag, labeltag } from "src/assets";
import { Helmet } from "react-helmet";
import { truncateDescription } from "src/helpers";
import { NewCaseStudies } from "./SingleCaseStudy/NewCaseStudies";

const Index = () => {
  const filterOptions = ["See All", "Education", "Learning Management System", "Real Estate", "Shopify", "E-Commerce", "OpenCart Extension", "Agriculture", "Healthcare API Development"];
  const [selectedOption, setSelectedOption] = useState("See All");
  const [filteredCasestudies, setFilteredCaseStudies] = useState(NewCaseStudies);


  useEffect(() => {
    const filteredCaseStudies = selectedOption === "See All"
      ? NewCaseStudies
      : NewCaseStudies.filter((item: any) =>
        item.keywords.some(keyword => selectedOption === keyword)
      );
    setFilteredCaseStudies(filteredCaseStudies)
  }, [selectedOption])
  return (
    <div>
      <Helmet>
        <title>
          Case Studies | Ditinus
          Technology
        </title>
      </Helmet>
      <main className="bg-height4 bg-kpo-bpo our-portfolio1">
        <section className="subBannerCaseStudies ">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-xl-12 col-sm-12 col-md-12 col-12 col-lg-12 text-center top-banner">
                <h1 className="heading-223">A Glimpse Into Our Work
                </h1>
                <div className="row justify-content-center mt-2">
                  <div className="col-lg-8 col-md-11 col-sm-12">
                    <p className="text-white" style={{ fontWeight: "400" }}>
                      Explore Our Expertise
                    </p>
                  </div>
                  <div className="col-lg-8 col-xl-8 col-md-11 col-sm-12">
                    <p className="fs-6 fw-normal p-0 m-0 text-white">
                      Our case studies below showcase the innovative spirit that drives us. Over the past few years, we have successfully developed and deployed numerous critical projects across diverse industries. Here are a few examples that illustrate how we excel at what we do.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="case-Study-section-new">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 col-sm-6">
                <div className="d-flex gap-1 flex-column align-items-center">
                  <h4 className="heading-01-figures text-uppercase text-WH d-flex justify-content-center">
                    96 <span className="d-flex font-bold figure-symbol">%</span>
                  </h4>
                  <p className="text-WH text-uppercase text-center margin-right-2 figure-description pe-4">
                    Client success rate
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 col-sm-6">
                <div className="d-flex gap-1 flex-column align-items-center ">
                  <h4 className="heading-01-figures text-uppercase text-WH d-flex justify-content-center">
                    1350 <span className="d-flex font-bold figure-symbol">+</span>
                  </h4>
                  <p className="text-WH text-uppercase text-center margin-right-2 figure-description  pe-4">
                    Projects Completed
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-6 col-sm-6">
                <div className="d-flex gap-1 flex-column align-items-center ">
                  <h4 className="heading-01-figures text-uppercase text-WH d-flex justify-content-center">
                    20<span className="d-flex font-bold figure-symbol">+</span>
                  </h4>
                  <p className="text-WH text-center text-uppercase figure-description pe-4">
                    Industries Served
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6  col-6 col-sm-6 ">
                <div className="d-flex gap-1 flex-column align-items-center">
                  <h4 className="heading-01-figures text-uppercase text-WH d-flex ">
                    $5M<span className="d-flex font-bold figure-symbol">+</span>
                  </h4>
                  <p className="text-WH  text-center text-uppercase  figure-description pe-4">
                    Managed in ad spent
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="my-5">
          <div className="container">
            <div className="row justify-content-center px-2">
              <div style={{ background: '#0D72B8' }} className="col-12 col-sm-12 col-lg-8 col-xl-8 col-xxl-8 py-3 px-2 d-flex flex-row flex-wrap justify-content-center align-items-center border border-3 rounded-5 gap-2">
                {filterOptions?.map((el, index) => (
                  <div key={index} onClick={() => setSelectedOption(el)} className={`d-flex justify-content-center align-items-center flex-wrap rounded-5 py-2 px-4 ${selectedOption === el ? "bg-white" : "bg-none"}`}>
                    <h6 style={{ cursor: 'pointer' }} className={` p-0 m-0 ${selectedOption === el ? "text-denim" : "text-white"}`}>{el}</h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="menu-item-section">
          <div className="container">
            <div className="menu-tabs">
              <div className="row">
                <div className="right-menu">
                  <div className="tab-panel">
                    <div
                      className="tab-pane active tab-content"
                      id="see-all"
                      role="tabpanel"
                    >
                      <div className="row g-1 g-md-4 justify-content-center">
                        {filteredCasestudies.map((item, index) => (
                          <div
                            className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-sm-4 mt-md-4 mt-lg-3 mt-xl-3"
                            key={index}
                          >
                            <Link
                              to={`/case-study/${item.slug}`}
                              className="card"
                            >
                              <figure>
                                <img
                                  className="w-100"
                                  src={item.image2}
                                  alt="Portfolio"

                                />
                              </figure>
                              <div className="d-flex flex-row  gap-3 flex-wrap p-0 m-0 mt-0 jusitfy-content-start " key={index}>
                                {item?.keywords.map((el, index) => (
                                  <div className="d-flex flex-row gap-1">
                                    <img src={icontag} loading="lazy" className="p-0" alt="" />
                                    <p className="text-white p-0 m-0 text-wrap fw-normal caseStudies-tag">
                                      {el}
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <div className="label-tag py-2">
                                <h2>{item.title}</h2>
                              </div>
                              <p className="text-white p-0 m-0 fw-normal">
                                {item?.description ? truncateDescription(item.description, 90) : ""}
                              </p>
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >
      </main >
    </div >
  );
};

export default Index;